//import React, {Component} from 'react';
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './Order.css';
import companyLogo from "../../img/geotechlogo.png";
import { GET } from "../../api";
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';


const Order = (props) => {
    //    const dynamicStlye={ }


    const { t, i18n } = useTranslation();
    const [orderData, setOrderData] = useState({});

    let apiColor = orderData.apiColor;

    root.style.setProperty('--api-color', apiColor);

    const getOrder = async () => {
        const orderID = (props.url.orderQueryParametre.match.params.order).split('=');
        const url = `tracker/order-tracking/${orderID[1]}`;
        const { data: res } = await GET(url);
        if (res) {
            setOrderData(res);
        }
    }

    useEffect(() => {
        getOrder();
    }, []);


    const visitStatus = (params) => {
        switch (params) {
            case "VISIT_COMPLETED":
                return { outletvisitstatus: `${t('delivered')}`, statuslevel: 4 }
                break;
            case "VISIT_FAILED":
                return { outletvisitstatus: `${t('notDelivered')}`, statuslevel: 4 }
                break;
            default:
                return { outletvisitstatus: `${t('onTheWay')}`, statuslevel: 3 }
        }
    }
    const outletVisit = visitStatus(orderData.outletVisitStatus);


    const deliveryDate = (params) => {

        function pad(n) {
            return n < 10 ? '0' + n : n
        };
        
        // const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        // const month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        let deliveryDate = new Date(params);

        // var month = deliveryDate.toLocaleString(localStorage.i18nextLng, { month: 'long' });
        // var day = deliveryDate.toLocaleString(localStorage.i18nextLng, { weekday: 'long' });;
        // let convertDeliveryTime = deliveryDate.getDate() + " " + month + " " + deliveryDate.getFullYear() + " " + day;
        // let convertDeliveryTime = deliveryDate.getDate() + " " + month[deliveryDate.getMonth()] + " " + deliveryDate.getFullYear() + " " + days[deliveryDate.getDay()];
        let dt = pad(deliveryDate.getDate()) + "." + pad(deliveryDate.getMonth() + 1) + "." + deliveryDate.getFullYear();
        return dt;
    }

    const timeRange = (hour) => {
        let spHour = hour.split(":");
        let h = new Date();
        h.setHours(spHour[0], spHour[1], 0);
        h.setHours(h.getHours() + 3);
        return spHour[0] + ":00" + " - " + h.getHours().padLeft() + ":00";
    }

    const convertSecondsToHourFormat = (seconds, showSecond) => {
        // seconds = seconds + (new Date().getTimezoneOffset() * -1 * 60) || 0;
        let mins = Math.floor(seconds / 60);
        let s = Math.round(seconds - (mins * 60));
        let minutes = Math.floor(mins) % 60;
        let hours = Math.floor(mins / 60);
        return hours.padLeft() + ":" + minutes.padLeft() + (showSecond ? ":" + s.padLeft() : "");
    }
    let deliveryHour = convertSecondsToHourFormat((outletVisit.statuslevel != 3) ? (orderData.actualizationEndTime != null ? orderData.actualizationEndTime : orderData.startTime) : orderData.startTime);

    return (
        <section className="main-section">
            <main className="app-main">
                <section className="summary-content m-scroll">
                    <div className="container-fluid">
                        <div className="summary-line">
                            <div className="detail-list">
                            {orderData.companyLogo && <img src={orderData.companyLogo} alt="..." className="img-thumbnail styleLogo"></img>}
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('signName')}</label>
                                    <span className="col">{orderData.signName}</span>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('phone')}</label>
                                    <span className="col">{orderData.phoneNumber}</span>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('sender')}</label>
                                    <span className="col">{orderData.signName}</span>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('orderCode')}</label>
                                    <span className="col">{orderData.orderCode}</span>
                                </div>
                            </div>
                        </div>

                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('orderAddress')}</label>
                                    <span className="col">{orderData.address}</span>
                                </div>
                            </div>
                        </div>

                        <div>

                        </div>
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('orderDate')}</label>
                                    <span className="col">{deliveryDate(orderData.sessionCreatedDate)}</span>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className="summary-line">
                            <p><span className="order-status">{outletVisit.outletvisitstatus}</span></p>
                            <div className={`status-leveller level-${outletVisit.statuslevel} mb-3`}>
                                <div className="leveller-circle leveller-1"><i className="fas fa-handshake"></i></div>
                                <div className="leveller-circle leveller-2"><i className="fas fa-truck-loading"></i></div>
                                <div className="leveller-circle leveller-3"><i className="fas fa-truck"></i></div>
                                <div className="leveller-circle leveller-4"><i className="fas fa-box-open"></i></div>
                            </div>
                            {props.estimatedDeliveryStatus && <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('deliveryTime')}</label>
                                    <span className="col">{deliveryDate(orderData.sessionCreatedDate)}, {timeRange(deliveryHour)}</span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>
            </main>
        </section>
    )

}

export default Order
/* eslint-disable */