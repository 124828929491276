/* eslint-disable */
import React, { useState, useMemo, useRef, useContext, useEffect } from 'react'
import { MapContainer, TileLayer, Circle, Marker, Popup, useMap } from 'react-leaflet'
import { SmsContext } from "../contexts/SmsContext";
import "./MapCont.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import marker from "../../img/iconMarker.svg"
// import ExtendedMarker from './ExtendedMarker.jx';
export const MapContent = (props) => {


    const setOrderCoordinate = props.setordercood;
    const { latitude, longitude } = props.order;
    const position = [latitude, longitude];

    // const { pos, setPosition } = useContext(SmsContext);
    const [pos, setPosition] = useState(null);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [showPopUp, setShowPopUp] = useState(true);
    const [renderState, setRenderState] = useState(true);

    const markerRef = useRef(null);

    const markerIcon = new L.Icon();

    markerIcon.options.iconUrl = marker;
    markerIcon.options.iconAnchor = new L.Point(16, 16),
    markerIcon.options.iconSize   = new L.Point(32, 32);
    markerIcon.options.size       = new L.Point(32, 32);
    markerIcon.options.shadowUrl  = null;
    markerIcon.options.shadowSize = new L.Point(32, 32);

    var elements = document.getElementsByClassName('icon-marker');
    // elements.style.fill = "blue"

    if (!navigator.geolocation) {
        alert('Not suported browser');
    } else {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            //console.log(position.coords.latitude)
            localStorage.setItem("location", JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude }));

        }, () => {
            // alert("Location not retrived");
            console.log('Location not retrived');
        });
    }

    useEffect(() => {
        var loc = JSON.parse(localStorage.getItem("location"));
        if (loc) {
            setLat(loc.lat);
            setLng(loc.lng);
        }
        console.log("xxx");
    }, [lng])

    function ChangeView({ center, zoom }) {

        const map = useMap();
        map.setView(center, zoom);

        const showLocation = L.easyButton('<span class="showLocation">&target;</span>', function (btn, map) {

            if (lat && lng) {
                map.setView([lat, lng], 18);
                map.setZoom(18);
                setPosition([lat, lng]);
                setOrderCoordinate({ "latitude": lat, "longitude": lng })
                setTimeout(() => {
                    setPosition(null)
                }, 3000);
            }

        }, { position: "bottomright" });


        if (renderState) showLocation.addTo(map);


        const popup = markerRef.current;
        if (popup) {
            setTimeout(() => {
                setShowPopUp(false);
                // leaflet-popup-pane
                map.closePopup();
                // popup.closePopup('close');
            }, 3000)
            popup.openPopup();
        }


        setRenderState(false);
        return null;
    }


    // function appx(params) {
    //     const aa = useMap();
    //     L.easyButton('<i class="showLocation" style="font-size:18px;">gps_fixed</i>', function(){
    //         locateMe();
    //     }, {position: 'bottomright'}).addTo(aa);
    // }
 
 

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                // marker.openPopup();
                if (marker != null) {
                    setOrderCoordinate({ "latitude": marker.getLatLng().lat, "longitude": marker.getLatLng().lng })
                    setPosition(marker.getLatLng())
                }
            }
        }),
        [],
    )

    return (
        <div className="col-lg-8 sms-map-content mapParentRow">
            {Object.keys(props.order).length > 1 && (

                <MapContainer center={pos != null ? pos : position} zoom={18} scrollWheelZoom={true}>
                    <ChangeView center={pos != null ? pos : position} zoom={18} />
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker icon={markerIcon} eventHandlers={eventHandlers} ref={markerRef} position={pos != null ? pos : position} draggable={true}>
                        {/* {
                            showPopUp ?
                                <Popup>
                                    <span>A pretty CSS3 popup. <br /> Easily customizable.</span>
                                </Popup>
                                : null
                        } */}
                    </Marker>
                </MapContainer>

            )}

        </div>

    )
}
/* eslint-disable */