/* eslint-disable */
import React, { useContext, useState } from 'react'
import { SmsContext } from "../contexts/SmsContext"
import './Left.css';
import { GET } from "../../api";
import { AutoComplete } from "./AutoComplete"
import { useTranslation } from 'react-i18next';

export const LeftSide = (props) => {
    const { t, i18n } = useTranslation();
    //const { position } = useContext(SmsContext);
    let root = document.documentElement;
    let apiColor = props.order.apiColor || "#6E6C82";
    let apiIconColor = "#7cade1"

    const { signName, address } = props.order;
    const [selectedSuggestionAdress, setSelectedSuggestionAdress] = useState(null);

    root.style.setProperty('--api-color', apiColor);
    // root.style.setProperty('--api-icon-color', apiIconColor);

    const saveOrder = () => {
        console.log(props.ordercood)
        console.log(props.order)
        console.log(selectedSuggestionAdress)
        let objectOrder = { "coordinate": props.ordercood }
        console.log(objectOrder)
    };


    return (
        <div className="col-lg-4 sms-left-side">
            <div className="form-group m-0 fieldName">
                {props.order.companyLogo && <img src={props.order.companyLogo} alt="..." className="img-thumbnail styleLogo"></img>}
                <label className="p-3 label-user-name"> {signName} </label>
            </div>
            {/* <form> */}
            <fieldset className="fieldsetGeocodeLeft p-2">
                <div>
                    {/* {position.lat} - {position.lng} */}
                </div>
                {/* <div class="form-group m-0">
                        <label>sadsadsaj alksdjlksa</label>
                       <textarea rows="2" id="disabledTextInput" class="form-control" placeholder="saldlsamdm skadmsad" disabled /> 
                    </div> */}
                <div className="form-group mapAdress m-0 pt-1">
                    <label>{address}</label>
                    {/* <textarea rows="3" id="disabledTextInput" class="form-control" placeholder="samdksalkdmlksamdlkmsalkd sd s sdsdsdsd" disabled /> */}
                </div>
                <div className="form-group newAddress pt-3">
                    {/* <label>{t('newAddress')}</label> */}
                    <AutoComplete setordercood={props.setordercood} orderId={props.orderId} onChange={(selectedAdress) => setSelectedSuggestionAdress(selectedAdress)} />

                </div>
                <button type="submit" onMouseDown={saveOrder} className="btn btn-primary adress-btn-submit">{t('save')}</button>
            </fieldset>
            {/* </form> */}
        </div>
    )
}
/* eslint-disable */