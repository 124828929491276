/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import { LeftSide } from "./LeftSide";
import { MapContent } from "./MapContent";
//import { SmsContext } from "../contexts/SmsContext";
import { GET } from "../../api";


const SmsApp = (props) => {
    console.log(props);

    const [serviceReady, setServiceReady] =  useState(false);

    const [orderValue, setOrderValue] = useState({});
    const [orderId, setOrderId] = useState();
    const [orderCoordinate, setOrderCoordinate] = useState({});


    const contextValue = { orderCoordinate, setOrderCoordinate }

    const getOrder = async () => {
        const orderID = (props.orderQueryParametre.match.params.order).split('=');
        setOrderId(orderID);
        const url = `tracker/order-tracking/${orderID[1]}`;
        const { data: res } = await GET(url);
        if (res) {
            setOrderValue(res);
            setOrderCoordinate({ "latitude": res.latitude, "longitude": res.longitude });
            setServiceReady(true);
        }
    }

    useEffect(() => {
        getOrder();
    }, [])




    return (
        // <SmsContext.Provider value={orderCoordinate,setOrderCoordinate}>
        <div className="container-fuild">
        { serviceReady ? 
            <div className="row">
                <LeftSide order={orderValue} ordercood={orderCoordinate} setordercood={setOrderCoordinate} orderId={orderId} />
                <MapContent order={orderCoordinate} setordercood={setOrderCoordinate} />
            </div> :
            <div className="loader"> 
             <Loader
               type="Puff"
               color="#00BFFF"
               height={100}
               width={100}
               timeout={20000}
             />
            </div>
        }
        </div>
        // </SmsContext.Provider>
    )
}

export default SmsApp;

/* eslint-disable */


