/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import Order from '../order/Order';
import { useTranslation } from 'react-i18next';
import SmsApp from '../sms/SmsApp';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { Button,Alert, Container, Col, Row } from 'react-bootstrap';
import { POST } from "../../api";
import { GET } from "../../api";

import PropTypes from 'prop-types';
import SuccessPage from "../order/SuccessPage";
import '../delivererRate/DelivererRate.css'


const DelivererRate = (props) => {
  const { t, i18n } = useTranslation();
  const [rateState, setRateState] = new useState();
  const [feedBack, setFeedBack] = new useState();
  const [redirectStatus, setRedirectStatus] = useState(false);

  const [orderData, setOrderData] = useState({});
  const [serviceReady, setServiceReady] = useState(false);

  let apiColor = orderData.apiColor;

  root.style.setProperty('--api-color', apiColor);

  // setTimeout(function() {  
  //  var viewport = document.querySelector("meta[name=viewport]");
  //   viewport.setAttribute('content', 'height=auto');
  // }, 300);

  const orderID = (props.orderQueryParametre.match.params.order).split('=')[1];

  const changeRating = (newRating, name) => {
    setRateState({
      rating: newRating
    });
  }

  const handleChange = (event) => {
    setFeedBack(event.target.value);
  }

  const delivererDate = async () => {
    let data = {
      // type: "customer-rate",
      rate: rateState.rating,
      feedback: feedBack
    };

    const url = `tracker/order-tracking/customer-rate/${orderID}`;
    const glndata = await POST(url, data);

    if (glndata.status) {
      //alert("başarılı")
      setRedirectStatus(true)
    }
  }

    const getOrder = async () => {
      const orderID = (props.orderQueryParametre.match.params.order).split('=');
      const url = `tracker/order-tracking/${orderID[1]}`;
      const { data: res } = await GET(url);
      if (res) {
          setServiceReady(true);
          setOrderData(res);
      }
  }

  useEffect(() => {
      getOrder();
  }, [])

  // function AlertSuccessDismissible() {
  //   const [show, setShow] = useState(true);
  
  //   if (show) {
  //     return (
  //       <Alert variant="success">
  //         <Alert.Heading>Congratulation!</Alert.Heading>
  //         <p>
  //          İşleminiz başaralı bir şekilde gerçekleşti
  //         </p>
  //       </Alert>
  //     );
  //   }
  // }

  return( serviceReady ? (
  redirectStatus == false ? (
    <div className="deliverer-rate">
      <Order url={props} />
      <Row>
        <Col>
         <Row style={{ marginTop: 20, marginLeft: 90 }}>
          <StarRatings
            rating={rateState ? rateState.rating : 0}
            starRatedColor="yellow"
            changeRating={changeRating}
            numberOfStars={5}
            name='rating'
            starHoverColor='yellow'
            starDimension='25px'
          />
         </Row>
         <Row style={{ marginTop: 20, width: 250, marginLeft: 60 }}>
           <textarea value={feedBack} onChange={handleChange} rows="2" className="form-control" />
           {/* <Button onClick={delivererDate} style={{ display: "block", marginLeft:"170px",backgroundColor:"transparent" }} type="submit" className="">Gonder</Button> */}
         </Row>
        </Col>
      </Row>
      <Button onClick={delivererDate} style={{ display: "block" }} type="submit" className="btn btn-primary adress-btn-submit">{t('send')}</Button>

    </div>
  ) :
    (
      <SuccessPage />
    )
   ) :
   <div className="loader"> 
    <Loader
      type="Puff"
      color="#00BFFF"
      height={100}
      width={100}
      timeout={20000}
    />
  </div>
  )
}
DelivererRate.propTypes = {
  // rating: PropTypes.number.isRequired,
  feedback: PropTypes.string
};

export default DelivererRate;

/* eslint-disable */


