/* eslint-disable */
import React, { useState, useEffect } from 'react';
import  Order from '../order/Order';
import { useTranslation } from 'react-i18next';
import SmsApp from '../sms/SmsApp';
import { Link } from 'react-router-dom';


const OrderDetailUpdateApp = (props) => {
    const { t, i18n } = useTranslation();
    const orderID = (props.orderQueryParametre.match.params.order)

    return (

          <div>
           <Order url={props} />
           <div class="d-grid gap-3">
            <div class="p-2 bg-light border">
               <i class="far fa-map-pin m-2"></i>

              <Link>
               <span style={{color:"black"}}>{t('deliveryLocation')}</span>
              </Link>

             </div>
             <div class="p-2 bg-light border">
              <i class="fal fa-history m-2"></i>

             <Link to={`/sms/:${orderID}`}>
              <span style={{color:"black"}}>{t('changeLocation')}</span>
             </Link>
             
            </div>
           </div>
          </div> 
           
        )
}

export default OrderDetailUpdateApp;

/* eslint-disable */


