/* eslint-disable */
import React, { useEffect } from 'react';
import style from './MapContent.css';
import { MapContainer, TileLayer, Circle, Marker } from 'react-leaflet';
import L from 'leaflet';
import deliveryLogo from "../../img/delivery.svg";
import homeLogo from "../../img/home.svg";

 

const MapContent = ({ arrival, routeID, getLastPosition, lastPos }) => {

    const deliveryIcon = L.icon({
        iconUrl: deliveryLogo,
        iconRetinaUrl: deliveryLogo,
        iconSize: [35, 35],
    });

    const homeIcon = L.icon({
        iconUrl: homeLogo,
        iconRetinaUrl: homeLogo,
        iconSize: [35, 35],
    });



    useEffect(() => {
        getLastPosition(routeID);
        setInterval(() => {
            getLastPosition(routeID);
        }, 10000);
    }, [])


    const { latitude, longitude } = arrival;
    const centerBlue = [latitude, longitude];



    let centerRed;
    if (lastPos && (Object.keys(lastPos).length == 1)) {
        centerRed = lastPos;
    }



    return (
        <section className="map-section">
            <MapContainer center={centerBlue} zoom={11} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <Marker position={centerBlue} icon={homeIcon}></Marker>
                {/* {
                    !centerRed ? console.log("last-position verisi boş geliyor") : <Marker position={[centerRed.lastposition.latitude, centerRed.lastposition.longitude]} icon={deliveryIcon}></Marker>
                } */}

                {
                    (!centerRed || centerRed.lastposition == undefined) ? console.log("last-position verisi boş geliyor") : <Marker position={[centerRed.lastposition.latitude, centerRed.lastposition.longitude]} icon={deliveryIcon}></Marker>
                }
            </MapContainer>
        </section>
    )

}


export default MapContent

/* eslint-disable */





/*class mapContent extends Component {

    componentDidMount() {
        this.props.getLastPosition(this.props.routeID)
    }

    render() {
        const { latitude, longitude } = this.props.arrival;

        const centerBlue = [latitude, longitude];
        const fillBlueOptions = { fillColor: 'blue' };
        const fillRedOptions = { color: 'red' };

        //const centerRed = [51.400, -0.29];
        let centerRed;
        if (Object.keys(this.props.lastPos).length > 1) {
            centerRed = this.props.lastPos;
        }

        return (
            <section className="map-section">
                <MapContainer center={centerBlue} zoom={15} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Circle center={centerBlue} pathOptions={fillBlueOptions} radius={50} />
                    <Marker position={centerBlue}></Marker>
                    {
                        !centerRed ? console.log("yok") : <Circle center={[centerRed.latitude, centerRed.longitude]} pathOptions={{ color: 'red' }} radius={40} />
                    }
                </MapContainer>
            </section>
        )
    }
}


export default mapContent*/
