/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
//import { SmsContext } from "../contexts/SmsContext"
import "./AutoComplete.css"
import { GET } from "../../api";
import { useTranslation } from 'react-i18next';


export const AutoComplete = (props) => {

    //const { position, setPosition } = useContext(SmsContext)

    const orderId = props.orderId;
    const [selectedSuggestion, setSelectedSuggestion] = useState([]);
    const [suggestion, setSuggestion] = useState(null);
    const { t, i18n } = useTranslation();
    
    let newAddress = useRef();

    const getSuggestion = async (Val) => {
        const url = `suggestion-external?oid=${orderId[1]}&keyword=${Val}`;
        const { data: res } = await GET(url);
        if (res) {
            setSuggestion(res)
        }
    }

    useEffect(() => {
        props.onChange(selectedSuggestion)
    }, [selectedSuggestion])


    const onKeyUp = (event) => {
        let eventValue = event.target.value;
        setTimeout(function () {
            if (newAddress.current.value != eventValue || eventValue.length < 3) return
            getSuggestion(eventValue)
        }, 1000);
    }

    const handleSelect = (value) => {
        let newAdressValue = value.address;
        newAddress.current.value = newAdressValue;
        setSelectedSuggestion(newAdressValue);
        props.setordercood({ "latitude": parseFloat(value.latitude), "longitude": parseFloat(value.longitude) });
        setSuggestion([])
    };




    return (
        <div>
            <textarea rows="2" placeholder={t('newAddress')} className="form-control address-textarea" onKeyUp={onKeyUp} ref={newAddress} />

            {suggestion ? (
                <div>
                    <ul className="suggestionList">
                        {suggestion.map((item, i) => {
                            return (
                                <li className="suggestionListItem" onMouseDown={() => handleSelect(item)} key={i}><span>{item.address}</span></li>
                            )
                        })}
                    </ul>
                </div>
            ) : (
                <div></div>
            )}

        </div>
    )
}
/* eslint-disable */