//import React, { Component } from 'react'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import LeftSide from './LeftSide';
import MapContent from './MapContent';
import { GET } from "../../api";

const LiveApp = (props) => {


    const [lastPosition, setLastPosition] = useState([]);
    const [routeValue, setrouteValue] = useState({
        data: {},
        routeID: null,
        init: 0,
        outletVisitStatus: null
    });

    const [IntervalOrder, setIntervalOrder] = useState(null);


    const getOrder = async () => {
        var baseUrl = (props.orderQueryParametre.match.params.order);
        var orderID = baseUrl.split('=');
        if(orderID && orderID[0] == "order"){
            var url = `tracker/order-tracking/${orderID[1]}`;
            var { data: res } = await GET(url);
        }else{
           var params = getQueryStringParams(baseUrl);
           var url = `tracker/order-tracking/detail?cid=${params.cid}&id=${params.id}`;
           var { data: res } = await GET(url);
        }
        if (res) {
            setrouteValue({
                data: res,
                routeID: res.routeId,
                init: 1,
                outletVisitStatus: res.outletVisitStatus
            })
        }
    }


    const getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };

    const getLastPosition = async (routeID) => {
        if (!routeValue.routeID || routeValue.data.outletVisitStatus == "VISIT_COMPLETED" || routeValue.outletVisitStatus == "VISIT_FAILED") return
        const url = `tracker/firebase/last-position-by-route-id?routeId=${routeID}`;
        const { data: res } = await GET(url);
        if (res) {
            setLastPosition({ lastposition: res[0] })
        }
    }



    useEffect(() => {
        getOrder();
        let intervalID = setInterval(() => {
            getOrder();
        }, 15000);

        setIntervalOrder(intervalID);

    }, [])

    useEffect(() => {
        console.log('component did update');
        if (routeValue.data.outletVisitStatus == "VISIT_COMPLETED" || routeValue.data.outletVisitStatus == "VISIT_FAILED") {
            clearInterval(IntervalOrder)
        }
   
    },[routeValue.outletVisitStatus]);


    return routeValue.init ?
        (
            <main className="main-wrapper">

                <LeftSide order={routeValue.data} visitStatus={routeValue.outletVisitStatus} />
                <MapContent arrival={routeValue.data} routeID={routeValue.routeID} getLastPosition={getLastPosition} lastPos={lastPosition} />
            </main>
        )
        :
        (
            <main className="main-wrapper">
                <p>Looaing..</p>
            </main>
        )

}

export default LiveApp



/* eslint-disable */
