import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { GET_BRINGO_DOC } from "../../../api";

const DocSlide = (props) => {
  const [dc, setDc] = useState();
  const [count, setCount] = useState(0);
  
 let images = props.images;
 let imageRef = useRef();
  //let images = ["https://upload.wikimedia.org/wikipedia/tr/8/86/Fenerbah%C3%A7e_SK.png", "https://upload.wikimedia.org/wikipedia/commons/3/37/Galatasaray_Star_Logo.png", "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/BesiktasJK-Logo.svg/1200px-BesiktasJK-Logo.svg.png"];
  
 
  // const changeİmage = async () => {
  //   let imagesCount = images.length; //2
  //   setCount(count + 1);
  //   if(count >= imagesCount){
  //   setCount(0)
  //   }
  //   if(images[count] != undefined){
  //       const url = `document/${images[count].id}`; // new address
  //       const  res  = await GET_BRINGO_DOC(url);
  //       if (res) {
  //          setDc(res);
  //       }
  //   }else{
  //      firstİmage();
  //   }
  // }

  // const firstİmage = async () => {
  //   const url = `document/${images[0].id}`; // new address
  //   const  res  = await GET_BRINGO_DOC(url);
  //   if (res) {
  //      setDc(res);
  //   }
  // }

  // useEffect(() => {
  //   firstİmage();
  // }, [])

  const arrİmage =  () => {
    let x=[];
    images.map(d => {
      const url = `document/${d.id}`; // new address
      const  res  =  GET_BRINGO_DOC(url);
      x.push(res);
    })


    if (x) {
       setDc(x);
    }
  }

  useEffect(() => {
      arrİmage();
      setTimeout(() => {
        imageRef.current.firstChild.style.display = "block"
      }, 1000);
    
  }, [])

 
  useEffect(() => {
    let imagesCount = images.length;
    if(count === imagesCount){
      imageRef.current.firstChild.style.display = "block";
      imageRef.current.lastChild.style.display = "none";
      setCount(0)
      return
    }

    if(count > 0){
      let dd= count -1;
      imageRef.current.childNodes[dd].style.display = "none";
      imageRef.current.childNodes[count].style.display = "block";
    }
  }, [count])

  
  
  const frfr =  () => { 
    imageRef.current.childNodes.forEach((el,i) => {
      (i != 0) ? el.style.display = "none" : el.style.display = "block";
      setCount(0)
    })
  }

  return (
    <div
      class='modal fade'
      id='exampleModal4'
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog' role='document'>
        <div class='modal-content'>
          <div class='modal-header'>
            {/* <h5 class='modal-title' id='exampleModalLabel'>
              Modal title
            </h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button> */}
          </div>
          <div class='modal-body' ref={imageRef}>
             {
              //images.map(d => { return <img src={d} style={{ width: '100%', "max-height": "300px", "display": "none"}} />} )
              dc && dc.map(d => { return <img src={d} style={{ width: '100%', "max-height": "300px", "display": "none"}} />} )
             }
 
          </div>
          <div class='modal-footer'>
            <button
              type='button'
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={() => frfr()}
            >
              Kapat
            </button>
            {/* <button type='button' class='btn btn-primary' onClick={() => changeİmage()}> */}
            {
             images.length > 1 && <button type='button' class='btn btn-primary' onClick={() => setCount(count+ 1)}>
               ileri
             </button>
            }
        
          </div>
        </div>
      </div>
    </div>
  );
};
export default DocSlide;
