import React from 'react'
import logo from '../../img/logo_header.svg';

const Header = () => {
    return (
        <>
            <div class="card-header bg-danger">
                <img src={logo}  alt="..." style={{ width: "140px", float: "left" }} />
            </div>
        </>
    )
}

export default Header