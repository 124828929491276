import React, { useState } from 'react';
import { 
    Button,
    Alert, 
    Container, 
    Col,
    Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

export default function SuccessPage() {
    const [show, setShow] = useState(true);
    const { t } = useTranslation();
    
    const styleSuccess = {
        textAlign: 'center',
        marginTop: '80%',
        fontSize: '19px',
        color: '#37d819'
    }
    
    if (show) {
        return (
          <Alert variant="success">
            <Alert.Heading>{t('congratulation')}!</Alert.Heading>
            <p>
            {t('congratulationDetail')}
            </p>
          </Alert>
        );
      }
}
