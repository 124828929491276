import React, { useState, useEffect } from 'react'

const OrderStatus = (props) => {
    //const { order_code, status, history } = props.order;
    

    window.previousOrderStatus = "previousorderstatus";

    /**
     * Order History statuses
     */
    const orderStatusSwitch = (status) => {
        switch (status) {
            case 'ORDER_RECEIVED':
            case 'SENT_TO_PLAN':
            case 'REVERT_FROM_PLAN':
            case 'IN_WAREHOUSE':
            case 'DELIVERY_PLANNED':
            case 'BRANCH_ACCEPTED': 
            case 'IN_TRANSFER_CENTER':
            case 'ON_THE_WAY_FOR_TRANSFER':
            case 'ORDER_ACCEPTED':
                return { "icon": <i class="fal fa-box-check fIcon"></i> , "label": "MÜŞTERİDEN ÜRÜN ALINDI" };
            case 'ON_BOARD':
            case 'ON_THE_WAY_TO_CUSTOMER':
            case 'ON_THE_WAY_FOR_TRANSFER': 
                return { "icon": <i class="far fa-dolly-flatbed-alt fIcon"></i>, "label": "TRANSFER MERKEZİNDE" };
            case 'CUSTOMER_INFORMED_1':
            case 'CUSTOMER_INFORMED_2':
            case 'CUSTOMER_READINESS_RECEIVED':
            case 'CUSTOMER_INFORMED_3':
            case 'COURIER_DEBIT':
                return { "icon": <i class="fal fa-shipping-fast fIcon"></i>, "label": "DAĞITIMA ÇIKTI" };
            case 'DELIVERED':
            case 'PARTIALLY_DELIVERED':
                return { "icon": <i class="fal fa-people-carry fIcon"></i>, "label": "TESLİM EDİLDİ" };
            case 'NOT_DELIVERED':
            case 'DELIVERY_POSTPONED':
                return { "icon": <i class="fal fa-times fIcon"></i>, "label": "TESLİM EDİLEMEDİ" };
            default:
                return { "icon": "", "label": "" };
                break;
        }
    }

    let arr = [];

    const dateFormat = (hr) => {
        //var aa = hr.split("T");
        // return new Date(hr).toLocaleString()
        // let h = hr.split("T")
        // return h;

        var d = new Date(hr);
        d.setHours(d.getHours() + (d.getTimezoneOffset() / -60));
        let hh = d.toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' });
        let dd = d.toLocaleDateString()
        return [dd, hh]
    }

    return (

        <>
            {props.order.data && props.order.data != "undefined" && (
                <table class="table table-bordered" style={{ marginBottom: "40px" }}>
                    <thead>
                        <tr>
                            {/* <th scope="col"> Durum </th>
                            <th scope="col"> Tarih </th> */}
                            <th colspan="2"> Kargo Takip Numarası </th>
                        </tr>
                        <tr>
                            {/* <th scope="col"> Durum </th>
                            <th scope="col"> Tarih </th> */}
                            <th colspan="2" style={{ color: "#dc3545" }}> {props.order.data[0].order_code} </th>
                        </tr>
                        <tr>
                            {/* <th scope="col"> Durum </th>
                            <th scope="col"> Tarih </th> */}
                            <th colspan="2"> Sipariş Durumu </th>
                        </tr>
                    </thead>

                    <tbody>
                        {/* order status and history listed */}
                        {
                            props.order.data[0].history.map((dt) => {


                                let currentOrderStatus = orderStatusSwitch(dt.status).label;

                                if (window.previousOrderStatus == currentOrderStatus) return
                                window.previousOrderStatus = currentOrderStatus;


                                return (<tr>
                                    <th className="rowWdth" scope="row" style={{ "vertical-align": "inherit", "borderRight": "0px", "background": "#f7f4f4ad" }}>
                                        {/* <span className="order-track-status-dot"></span> */}
                                        {orderStatusSwitch(dt.status).icon}

                                    </th>
                                    <td style={{ "borderLeft": "0px" }}>
                                        <span style={{ "font-size": "15px", "fontWeight": "700", "color": "#dc3545" }}> {orderStatusSwitch(dt.status).label} </span> <br />
                                        <span style={{ "font-size": "15px", "color": "#6e6c82" }}>Tarih : {dateFormat(dt.date)[0]}</span> <br />
                                        <span style={{ "font-size": "15px", "color": "#6e6c82" }}>Saat : {dateFormat(dt.date)[1]}</span>
                                    </td>
                                </tr>)

                            })

                        }



                        
                
                    {/**
                     * Show received and not received status
                     */}
                    {/* {(props.order.data[0].status == "DELIVERED" || props.order.data[0].status == "PARTIALLY_DELIVERED" || props.order.data[0].status == "NOT_DELIVERED" || props.order.data[0].status == "DELIVERY_POSTPONED") &&  <tr> */}
                    {(["DELIVERED","PARTIALLY_DELIVERED","NOT_DELIVERED","DELIVERY_POSTPONED","COURIER_DEBIT"].includes(props.order.data[0].status)) &&  <tr>
                            <th className="rowWdth" scope="row" style={{ "vertical-align": "inherit", "borderRight": "0px", "background": "#f7f4f4ad" }}>
                                {/* <span className="order-track-status-dot"></span> */}
                                {orderStatusSwitch(props.order.data[0].status).icon}
                            </th>
                            <td style={{ "borderLeft": "0px" }}>
                                <span style={{ "font-size": "15px", "fontWeight": "700", "color": "#dc3545" }}>{orderStatusSwitch(props.order.data[0].status).label}</span>  <br />
                                <span style={{ "font-size": "15px", "color": "#6e6c82" }}>Tarih : {dateFormat(props.order.data[0].lastupdated)[0]}</span> <br />
                                <span style={{ "font-size": "15px", "color": "#6e6c82" }}>Saat : {dateFormat(props.order.data[0].lastupdated)[1]}</span>

                            </td>
                        </tr> }

                        {/* <tr>
                            <th className="rowWdth" scope="row">
                                 <span className="order-track-status-dot"></span> 
                                <i class="far fa-shopping-cart fIcon"></i>
                            </th>
                            <td>Sipariş Alındı 18.08.2021-12:00</td>
                        </tr>
                        <tr>
                            <th className="rowWdth" scope="row">
                                <span className="order-track-status-dot"></span> 
                                 <i class="far fa-dolly-flatbed-alt fIcon"></i> 
                                <i class="far fa-dolly fIcon"></i>
                            </th>
                            <td>Transfer M  19.08.2021- 12:00</td>
                        </tr>
                        <tr>
                            <th className="rowWdth" scope="row">
                                 <span className="order-track-status-dot" style={{ backgroundColor: "#e8e0e0" }}></span> 
                                <i class="fal fa-shipping-fast fIcon"></i>
                            </th>
                            <td colspan="2">Dağıtıma Ç  20.08.2021- 08:00</td>

                        </tr>
                        <tr>
                            <th className="rowWdth" scope="row">
                                 <span className="order-track-status-dot" style={{ backgroundColor: "#e8e0e0" }}></span> 
                                <i class="fal fa-people-carry fIcon"></i>
                            </th>
                            <td colspan="2">Teslim E  20.08.2021- 12:00</td>

                        </tr> */}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default OrderStatus