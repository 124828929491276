/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Modal, { closeStyle } from 'simple-react-modal'
import { Form, Col, Button } from 'react-bootstrap';
import style from './Footer.css';
import { useTranslation } from 'react-i18next';
import { POST } from "../../api";




const Footer = (props) => {

    const { t, i18n } = useTranslation();
    const [Visible, setVisible] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const [redirectStatus, setRedirectStatus] = useState(false);

    const nameForm = useRef(null)

    useEffect(() => {
        const orderID = (props.url.orderQueryParametre.match.params.order).split('=');
        setOrderID(orderID[1]);
    }, [])


    useEffect(() => {
        props.onChange(redirectStatus)
    }, [redirectStatus])


    const closeModal = () => {
        setVisible(false)
    }

    const openModal = () => {
        setVisible(true)
    }

    // private String oid;
    // private String type;
    // private String buildingNumber;
    // private String flatNumber;
    // private String receiverName;
    // private String receiverPhone;
    // private String description;

    const callMeAnyway = async () => {
        let jsondata = {
            type: "NOT_PRESS_BELL_CALL_ME",
        };
        const url = `tracker/order-tracking/delivery-option/${orderID}`;
        const glndata = await POST(url, jsondata);

        console.log(glndata);

        if (glndata.status === 200) {
            //window.location.href = `/sresult`;
            //alert("başarılı");
            setRedirectStatus(true)
        }
    }



    const giveNeighbor = async (event) => {
        event.preventDefault();
        const form = nameForm.current

        if (!form["formGridBuildingNumber"].value || !form["formGridFlatNumber"].value || !form["formGridNote"].value || !form["formGridNeighborName"].value) {
            alert("formu eksik doldurdunuz!");
            return
        }

        let jsondata = {
            type: "GIVE_NEIGHBOR",
            buildingNumber: form["formGridBuildingNumber"].value,
            flatNumber: form["formGridFlatNumber"].value,
            receiverName: form["formGridNeighborName"].value,
            receiverPhone: form["formGridNeighborPhone"].value,
            description: form["formGridNote"].value
        };
        const url = `tracker/order-tracking/delivery-option/${orderID}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            setVisible(false);
            //alert("başarılı")
            setRedirectStatus(true)
        }
    }

    const atMyLocation = async () => {
        let jsondata = {
            type: "I_WILL_RECEIVE",
        };
        const url = `tracker/order-tracking/delivery-option/${orderID}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            setRedirectStatus(true)
        }

    }

    return (
        <div>
            <div className="d-grid gap-3">
                <div className="p-2 bg-light border" onClick={() => callMeAnyway()}>
                    <i className="far fa-bell m-2"></i>
                    <span>{t('callMeAnyway')}</span>
                </div>
                <div className="p-2 bg-light border" onClick={() => openModal()}>
                    <i className="fal fa-home m-2"></i>
                    <span>{t('neighbor')}</span>

                </div>
                <div className="p-2 bg-light border" onClick={() => atMyLocation()}>
                    <i className="fal fa-home m-2"></i>
                    <span>{t('atMyLocation')}</span>
                </div>
            </div>


            <Modal containerStyle={{ "borderRadius": "10px", width: 350 }} show={Visible} effect="fadeInUp">
                <Form ref={nameForm}>
                    {/* <Modal.Header closeButton>
                    <Modal.Title>Okay</Modal.Title>
                      </Modal.Header> */}
                    <h5 style={{ "textAlign": "center" }}>{t('neighborInformation')}</h5>
                    <hr />
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBuildingNumber">
                            <Form.Label>{t('apartmentNumber')}</Form.Label>
                            <Form.Control />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridFlatNumber">
                            <Form.Label>{t('flatNumber')}</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formGridNeighborName">
                        <Form.Label>{t('neighborName')}</Form.Label>
                        <Form.Control />
                    </Form.Group>

                    <Form.Group controlId="formGridNeighborPhone">
                        <Form.Label>{t('neighborPhone')}</Form.Label>
                        <Form.Control />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridNote">
                            <Form.Label>{t('notes')}</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                    </Form.Row>

                    <Button onClick={() => closeModal()} variant="primary mr-1" type="submit">
                    {t('close')}
                    </Button>
                    <Button onClick={() => giveNeighbor(event)} variant="success" type="submit">
                    {t('save')}
                     </Button>
                </Form>
                {/* <div>
                    <div class="input-group">
                        <label>Komşu bilgileri</label>
                         <input className="inputWidth" type="text" placeholder="Apt. No*" />
                         <input className="inputWidth" type="text" placeholder="Daire. No" />
                         <input className="inputWidth" type="text" placeholder="Komşu Adı*" />
                         <input className="inputWidth" type="text" placeholder="Komşu Telefon" />
                         <textarea className="inputWidth" rows="2" placeholder="Notlar*" />
                    </div>
                    <button type="button" class="btn btn-primary btn-sm mr-1" onClick={() => closeModal()}>Kapat</button>
                    <button type="button" class="btn btn-success btn-sm" onClick={() => {alert("kaydedildi")}}>Kaydet</button>
                </div> */}
            </Modal>

        </div>
    )
}
export default Footer;
/* eslint-disable */
