/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import Order from '../order/Order'
import { GET } from "../../api";
import Footer from "./Footer";
import SuccessPage from "../order/SuccessPage";


const UpdateOrderOptionsApp = (props) => {
    const [redirect, setRedirect] = useState(false);
    const [serviceReady, setServiceReady] = useState(false);

    const getOrder = async () => {
        const orderID = (props.orderQueryParametre.match.params.order).split('=');
        const url = `tracker/order-tracking/${orderID[1]}`;
        const { data: res } = await GET(url);
        if (res) {
            setServiceReady(true);
        }
    }
  
    useEffect(() => {
        getOrder();
    }, [])

    return ( serviceReady ? (
    redirect == false ? (

        <div>
            <Order url={props} estimatedDeliveryStatus={true} />
            <Footer url={props} onChange={(redirectValue) => setRedirect(redirectValue)} />
        </div>
    ) :
        (
            <SuccessPage />
        )
    ) :

        <div className="loader"> 
            <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={20000}
            />
        </div>
  )
}

export default UpdateOrderOptionsApp;

/* eslint-disable */


