import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";




const resources = {
  en: {
    translation: {
      orderDetail: 'Order Detail',
      trackingNumber: 'Tracking Number',
      orderStatus: 'Order Status',
      delivered: 'Your Order Has Been Delivered',
      notDelivered: 'Your Order Has Not Been Delivered',
      onTheWay: 'Your Order on the Way',
      deliveryTime: 'Delivery Time',
      estimatedTime: 'Estimated Time Of Delivery',
      signName: 'Sign Name',
      quantity: 'Order Quantity',
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      adress: 'Adress',
      sender: 'Sender',
      orderCode: 'Order Code',
      orderDate: 'Order Date',
      deliveryLocation: 'At the delivery location',
      changeLocation: 'Change Delivery Location',
      callMeAnyway: 'Dont ring the bell, call me',
      neighbor: 'Deliver it with my neighbor',
      atMyLocation:'Delivery at my location',
      congratulation:'Congratulations',
      congratulationDetail:'Your transaction was successful',
      close: 'Close',
      save: 'Save',
      neighborInformation: 'Neighbor Information',
      apartmentNumber: 'Apt No*',
      flatNumber: 'Flat No*',
      neighborName: 'Neighbor Name*',
      neighborPhone: 'Neighbor Phone',
      notes: 'Notes',
      newAddress: 'New Address',
      send: 'Send',
      orderAddress: 'Address'
    }
  },
  tr: {
    translation: {
      orderDetail: 'Sipariş Detayı',
      trackingNumber: 'Sipariş No',
      orderStatus: 'Sipariş Durumu',
      delivered: 'Siparişiniz Teslim Edildi',
      notDelivered: 'Siparişiniz Teslim Edilmedi',
      onTheWay: 'Siparişiniz Yolda',
      deliveryTime: 'Teslim Zamanı',
      estimatedTime: 'Tahmini Teslim Zamanı',
      signName: 'Teslim Alan Kişi',
      quantity: 'Parça Adedi',
      name: 'Ad',
      phone: 'Telefon',
      email: 'Eposta',
      adress: 'Adres',
      sender: 'Ad',
      orderCode: 'Sipariş Kodu',
      orderDate: 'Teslimat Tarihi',
      deliveryLocation: 'Adresteyim, bekliyorum',
      changeLocation: 'Adres degistirmek istiyorum',
      callMeAnyway:'Zili Çalma, Geldiğinde Beni Ara',
      neighbor:'Komşuma Bırak',
      atMyLocation:'Adresteyim, Teslim Alacağım',
      congratulation:'Başarılı',
      congratulationDetail:'İşleminiz başaralı bir şekilde gerçekleşti',
      close: 'Kapat',
      save: 'Kaydet',
      neighborInformation: 'Komsu bilgileri',
      apartmentNumber: 'Apt No*',
      flatNumber: 'Daire No*',
      neighborName: 'Komşu Adı*',
      neighborPhone: 'Komşu Telefon',
      notes: 'Notlar',
      newAddress: 'Yeni Adres',
      send: 'Gonder',
      orderAddress: 'Teslimat Adresi'
    }
  },
  rs: {
    translation: {
      orderDetail: 'Детали Заказа',
      trackingNumber: 'Номер Заказа',
      orderStatus: 'Статус Заказа',
      delivered: 'Ваш заказ доставлен',
      notDelivered: 'Ваш заказ не доставлен',
      onTheWay: 'Ваш заказ уже в пути',
      deliveryTime: 'Время Доставки',
      estimatedTime: 'Расчетное Время Доставки',
      signName: 'Получатель',
      quantity: 'Количество ',
      name: 'Имя',
      phone: 'Номер Телефона',
      email: 'Эл. адрес',
      adress: 'Адрес',
      sender: 'Имя Отправителя',
      orderCode: 'Код Заказа',
      orderDate: 'Дата Доставки',
      deliveryLocation: 'Я по адресу, жду',
      changeLocation: 'Я хочу изменить адрес',
      callMeAnyway:'Не звони в домофон, позвоните мне, когда приедете',
      neighbor:'Оставьте мой заказ к моему соседу',
      atMyLocation:'Я нахожусь по адресу, я получу',
      congratulation:'Успешно',
      congratulationDetail:'Ваша доставка успешно доставлена',
      close: 'Закрыть',
      save: 'Сохранить',
      neighborInformation: 'Информация Вашего Соседа',
      apartmentNumber: 'Номер Дома',
      flatNumber: 'Номер Квартиры',
      neighborName: 'Имя Соседа',
      neighborPhone: 'Номер Телефона Соседа',
      notes: 'Примечание',
      newAddress: 'Новый Адрес',
      send: 'Отправить',
      orderAddress: 'Адрес Доставки'
    }
  } 
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}



i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    //lng: "tr",
    //lng: "rs",
    fallbackLng: "en",
    fallbackLng: "tr",
    fallbackLng: "rs",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
  });

  
export default i18n;
