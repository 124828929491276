import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import './desing1.css';
import logo from '../../img/logo_header.svg';
import box from '../../img/box.svg';
import deliveryMan from '../../img/deliveryMan.svg';
import rating from '../../img/rating.svg';
import StarRatings from 'react-star-ratings';

import { MapContainer, TileLayer, Circle, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet';
import homeLogo from "../../img/newHome.png";
import deliveryLogo from "../../img/newDelivery.svg"
import courier from "../../img/avatar.jpg";
import success_checked from "../../img/success_checked.png";
import phone from "../../img/phone.svg";
import passiveRadio from "../../img/deliveryPassiveRadio.svg";
import activeRadio from "../../img/deliveryActiveRadio1.svg";
import bell from "../../img/bell.svg";
import bellChecked from "../../img/bellChecked.svg";
import deliveryTimeIcon from "../../img/deliveryTimeIcon.svg";
import { GET_BRINGO } from "../../api";
import { GET } from "../../api";
import { POST } from "../../api";
import { BRINGO_CALL } from "../../api";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Spinner } from "../spinner/Spinner";
import DocSlide from './DocSlide/DocSlide';


const NewDesing1 = (props) => {
    const { t, i18n } = useTranslation();

    const [active, setActive] = useState("");
    const [live, setLive] = useState(false);
    const [beDeliveredToMyAddress, setBeDeliveredToMyAddress] = useState(null);
    const [orderToNeighbor, setOrderToNeighbor] = useState(null);
    const [options3, setOptions3] = useState(null);
    const [options4, setOptions4] = useState(null);
    const [options5, setOptions5] = useState(null);
    const [bellStatus, setBellStatus] = useState(null);
    const [neighborForm, setNeighborFrom] = useState({});
    const [order, setOrder] = useState();
    const orderID = (props.orderQueryParametre.match.params.order).split('=');
    const [newOrder, setNewOrder] = useState();
    const [map, setMap] = useState(null);
    const [lastPosition, setLastPosition] = useState([]);
    const [IntervalOrder, setIntervalOrder] = useState(null);
    const [rateState, setRateState] = new useState();
    const [feedBack, setFeedBack] = new useState();
    const [rateState2, setRateState2] = new useState();
    const [rateCourier, setRateCourier] = new useState();
    const [redirectStatus, setRedirectStatus] = new useState(false);
    const [liveTrackingOrder, setLiveTrackingOrder] = new useState();

    const [lastPositionIntervalID, setLastPositionIntervalID] = new useState();
    const [liveOrderIntervalID, setLiveOrderIntervalID] = new useState();
    //const [bounds, setBounds] = useState()
    const [liveRefresh, setLiveRefresh] = useState()
    const [supportCall, setSupportCall] = useState(null)
    const [lastPositionRq, setLastPositionRq] = useState(null)
    const [rect, setRect] = useState(null)
    const [differentDeliveryDate, setDifferentDeliveryDate] = useState();
    const [suggestion, setSuggestion] = useState(null);
    const [selectedSuggestion, setSelectedSuggestion] = useState();
    const [deliveryOptionsStatus, setDeliveryOptionsStatus] = useState();
    const [inputAddress, setInputAddress] = useState();
    const [evaluationPointerEvents, setEvaluationPointerEvents] = useState(null)
    const [orderTrackingStep, setOrderTrackingStep] = useState({
        step1:false,
        step2:false,
        step3:false,
        step4:false
    });
    const [lastElementInHistory, setLastElementInHistory] = useState(null);
    //const [deliveryCancelledFailCode, setDeliveryCancelledFailCode] = useState(null)

    const getOrder = async () => {
        //const url = `order-status-detail/${orderID[1]}`;
        const url = `tracking-page-info/${orderID[1]}`; // new address
        const { data: res } = await GET_BRINGO(url);

        if (res) {
            //test
            //res.data[0].status = "DELIVERED";
            // res.data[0].plan.start_time = null;
            // res.data[0].plan.date = null;
            //res.data[0].history[6].fail_code="PACKAGE_CANCELLED"; 


            // let unique = [];
            // res.data[0].history.map(x => unique.filter(a => a.status == x.status ).length > 0
            //  ? unique.find((d,i) => d.status === x.status ? unique.splice(i,1,x) 
            //  : null) 
            //  : unique.push(x));
            // res.data[0].history = unique;
            // console.log(unique);
            
            // Dağıtıma çıkıldı.
            if(res.data[0] && res.data[0].plan && res.data[0].plan.start_time){ // plan check
                let plan = res.data[0].plan;
                let history = res.data[0].history;
                history.push({"date": plan.start_time, "status": "ON_BOARD"}) // "Dağıtıma çıktı" -> Add status manually 
                history.sort(function(a,b){
                    return new Date(a.date) - new Date(b.date);
                });
            }

            setNewOrder(res.data && res.data[0])
        }

    }

    useEffect(() => {
        /**
         * api request on first page load 
         */
        getOrder();
    }, [])

    useEffect(() => {
        var cloneNewOrder;
        var latsElement;
        if(newOrder){
             newOrder.history = newOrder.history.filter(s => s.status != "DELIVERY_PLANNED"); // Remove "DELIVERY PLANNED" status
             cloneNewOrder =[...newOrder.history];
             latsElement= cloneNewOrder && cloneNewOrder.pop();
             setLastElementInHistory(latsElement)
        }
        //const label = newOrder && orderStatusSwitch(newOrder.status).label;
        const label = newOrder && orderStatusSwitch(latsElement.status).label;
        if(label === "Teslim Edildi") {
            setOrderTrackingStep({step1:true, step2:true, step3:true, step4:true})
        }else if(label === "Dağıtıma Çıktı"){
            setOrderTrackingStep({step1:true, step2:true, step3:true, step4:false})
        }else if(label === "Paket Kabul Edildi"){
            setOrderTrackingStep({step1:true, step2:false, step3:false, step4:false})
        }else if(label === "Transfer Merkezinde"){
            // Transfer merkezinde
            // if(latsElement.status === "DELIVERY_PLANNED"){
            //     setOrderTrackingStep({step1:true, step2:false, step3:false, step4:false})
            // }else{
            //     setOrderTrackingStep({step1:true, step2:true, step3:false, step4:false})
            // }
            setOrderTrackingStep({step1:true, step2:true, step3:false, step4:false})
        }else if(label === "Teslim Edilemedi"){
            setOrderTrackingStep({step1:true, step2:true, step3:true, step4:false})
        }else if(label === "Depoda Bulunamadı"){
            // DELIVERY_CANCELLED status
            // fail_code check
            if(latsElement.fail_code === "PACKAGE_NOT_FOUND"){
                setOrderTrackingStep({step1:true, step2:true, step3:false, step4:false})
            }else if( latsElement.fail_code === "PACKAGE_CANCELLED"){
                setOrderTrackingStep({step1:true, step2:true, step3:true, step4:false})
            }
        } else{
            setOrderTrackingStep({step1:false, step2:false, step3:false, step4:false}) 
        }
    }, [newOrder])

    // useEffect(() => {

    //   console.log("aAAAAA:Aaaa ", aa)
    // }, [aa])


    window.previousOrderStatus = "previousorderstatus";

    const notify = (mes) => mes ? toast(mes) : toast("İşleminiz Başarılı");

    function formatPhoneNumber(value) {
        let phone1 = value.substring(0, 4);
        let phone2 = value.substring(4, 7);
        let phone3 = value.substring(7, 9);
        let phone4 = value.substring(9, 11);
        return phone1 + " " + phone2 + " " + phone3 + " " + phone4;
    }

    function upperWord(string) {
        let wordArr = string.split(/(\s+)/).filter(function (e) { return e.trim().length > 0; });
        let word1 = wordArr[0].charAt(0).toUpperCase() + wordArr[0].slice(1).toLowerCase();
        let word2 = wordArr[1].charAt(0).toUpperCase() + wordArr[1].slice(1).toLowerCase();
        return word1 + " " + word2;
    }
















    /* ==================>>>>>>>>>>>>> orderStatus <<<<<<<<<<<<<<<<========================== */


    const orderStatusSwitch = (status) => {
        switch (status) {
            case 'ORDER_RECEIVED':
                return { "icon": <i class="fal fa-dolly-flatbed-alt fIcon"></i>, "label": "Teslim Alınmayı Bekliyor" };
            case "ORDER_ACCEPTED":
            //case "DELIVERY_CANCELLED": // fail_code = PACKAGE_CANCELLED
                //return { "icon": <i class="fal fa-box-check fIcon"></i> , "label": "Müşteriden Ürün Alındı" };
                return { "icon": <i class="fal fa-box-check fIcon"></i> , "label": "Paket Kabul Edildi" };
            case 'SENT_TO_PLAN':
            case 'REVERT_FROM_PLAN':
            case 'IN_WAREHOUSE':
            case 'DELIVERY_PLANNED':
            case 'IN_TRANSFER_CENTER':
            case 'BRANCH_ACCEPTED':
            case 'BRANCH_RETURN_ACCEPTED':
            case 'ON_THE_WAY_FOR_TRANSFER': // hepsi pasif olacak
            case 'COURIER_DEBIT':
                return { "icon": <i class="fal fa-dolly-flatbed-alt fIcon"></i>, "label": "Transfer Merkezinde" };
            case 'ON_BOARD': // 1 aktif, 2 3 pasif
            case 'ON_THE_WAY_TO_CUSTOMER': // 1 aktif, 2 3 pasif
            case 'CUSTOMER_INFORMED_1':  // 1 aktif, 2 3 pasif 
            case 'CUSTOMER_INFORMED_2': // 1 pasif , 2 aktif, 3 pasif 
            case 'CUSTOMER_READINESS_RECEIVED':  // 1 pasif , 2 aktif, 3 pasif
            case 'CUSTOMER_INFORMED_3':  // 1 pasif , 2 aktif, 3 pasif
            //case 'COURIER_DEBIT':
            //case 'DELIVERY_CANCELLED': // fail_code = PACKAGE_NOT_FOUND 
                return { "icon": <i class="fal fa-shipping-fast fIcon"></i>, "label": "Dağıtıma Çıktı" };
            case 'DELIVERED': //1 ve 2 pasif 3 aktif
            case 'PARTIALLY_DELIVERED': // hepsi pasif
                return { "icon": <i class="fal fa-people-carry fIcon"></i>, "label": "Teslim Edildi" };
            case 'NOT_DELIVERED':  // hepsi pasif
            case 'DELIVERY_POSTPONED':  // hepsi pasif
            case 'WILL_BE_RETURN': // -> iade edilecek
                return { "icon": <i class="fal fa-times fIcon" style={{ "width": "34px", "textAlign": "center" }}></i>, "label": "Teslim Edilemedi" };
            case 'DELIVERY_CANCELLED':
                return { "icon": <i class="fal fa-times fIcon" style={{ "width": "34px", "textAlign": "center" }}></i>, "label": "Depoda Bulunamadı" };
            default:
                return { "icon": "", "label": "" };
                break;
        }
    }




    /* ==================>>>>>>>>>>>>> orderStatus-*** <<<<<<<<<<<<<<<<========================== */












    /* ==================>>>>>>>>>>>>> Header <<<<<<<<<<<<<<<<========================== */


    const visitStatus = (params) => {
        switch (params) {
            case "VISIT_COMPLETED":
                return { outletvisitstatus: `${t('delivered')}`, statuslevel: 4 }
                break;
            case "VISIT_FAILED":
                return { outletvisitstatus: `${t('notDelivered')}`, statuslevel: 4 }
                break;
            default:
                return { outletvisitstatus: `${t('onTheWay')}`, statuslevel: 3 }
        }
    }

    const deliveryDate = (params) => {
        if(params === null) return "-"
        function pad(n) {
            return n < 10 ? '0' + n : n
        };

        let deliveryDate = new Date(params);
        let dt = pad(deliveryDate.getDate()) + "." + pad(deliveryDate.getMonth() + 1) + "." + deliveryDate.getFullYear();
        return dt;
    }

    const timeRange = (hour) => {
        let spHour = hour.split(":");
        let h = new Date();
        h.setHours(spHour[0], spHour[1], 0);
        h.setHours(h.getHours() + 3);
        return spHour[0] + ":00" + " - " + h.getHours().padLeft() + ":00";
    }

    const departureTimeAddHours = (hour) => {
        let spHour = hour.split(":");
        var h = new Date();
        h.setHours(spHour[0], spHour[1], 0);
        h.setHours(h.getHours() + 3);
        // console.log("xxxx: ", h.getHours())
        // console.log("aaaa: ", h.getMinutes())

        //return h.getHours() + " : " + h.getMinutes()

        //show two digit numbers
        return h.toTimeString().slice(0, 2) + " : " + h.toTimeString().slice(3, 5)
    }

    const hourFormat = (date, format) => {
       var h = new Date(date);
       var ho = format != "orj" ? h.setHours(h.getHours() + 3) : h; 
       return new Date(ho).toLocaleTimeString().substr(0,5);
    }
 

    const convertSecondsToHourFormat = (seconds, showSecond) => {
        // seconds = seconds + (new Date().getTimezoneOffset() * -1 * 60) || 0;
        let mins = Math.floor(seconds / 60);
        let s = Math.round(seconds - (mins * 60));
        let minutes = Math.floor(mins) % 60;
        let hours = Math.floor(mins / 60);
        return hours.padLeft() + ":" + minutes.padLeft() + (showSecond ? ":" + s.padLeft() : "");
    }
    const outletVisit = visitStatus(order && order.outletVisitStatus);
    let deliveryHour = convertSecondsToHourFormat((outletVisit.statuslevel != 3) ? (order && order.actualizationEndTime != null ? order && order.actualizationEndTime : order && order.startTime) : order && order.startTime);




    /* ==================>>>>>>>>>>>>> Header-*** <<<<<<<<<<<<<<<<========================== */












    /* ==================>>>>>>>>>>>>> Delivery-options <<<<<<<<<<<<<<<<========================== */



    // get delivery description
    let deliveryType = newOrder && newOrder.delivery_option != null ? JSON.parse(newOrder.delivery_option).type : "";

    useEffect(() => {

        if (deliveryType == "I_WILL_RECEIVE") {
            setBeDeliveredToMyAddress(true)
        } else if (deliveryType == "GIVE_NEIGHBOR") {
            setOrderToNeighbor(true);
        } else if (deliveryType == "I_WILL_RECEIVE_LATER") {
            if (JSON.parse(newOrder.delivery_option).newDeliveryDate) { // if there is a date
                setOptions4(true);
            } else {
                setOptions3(true);
            }

        } else if (deliveryType == "NOT_PRESS_BELL_CALL_ME") {
            setBeDeliveredToMyAddress(true);
            setBellStatus(true);
        } else if (deliveryType == "CHANGE_DELIVERY_ADDRESS") {
            setOptions5(true);
        }

    }, [deliveryType])

    /**
     * from my current location, I will buy the product
     */

    const deliveredToMyAddress = async (e) => {

        // setBeDeliveredToMyAddress(true);
        // setOrderToNeighbor(false);
        // setOptions3(false);
        // setOptions4(false);
        // setOptions5(false);

        let jsondata = {
            type: "I_WILL_RECEIVE",
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            setRedirectStatus(true);
            notify()
            window.location.reload();


            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        }
    }





    /**
     * another time option, function
     */

    const delivertOtherOptions = async (e) => {
        // setOrderToNeighbor(false);
        // setBeDeliveredToMyAddress(false);
        // setOptions3(true);
        // setBellStatus(false);
        // setOptions4(false);
        // setOptions5(false);

        let jsondata = {
            type: "I_WILL_RECEIVE_LATER",
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            setRedirectStatus(true);
            notify()

            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        }
    }




    /**
     * Don't press the bell
     */

    const bellClicked = async (e) => {
        // setOrderToNeighbor(false);
        // setBeDeliveredToMyAddress(false);
        // setOptions3(true);

        //bellStatus == true ? setBellStatus(false) : setBellStatus(true);

        // let jsondata = {
        //     type: bellStatus == true ? "I_WILL_RECEIVE" : "NOT_PRESS_BELL_CALL_ME"
        // };

        let jsondata = {
            type: "NOT_PRESS_BELL_CALL_ME"
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status === 200) {
            //window.location.href = `/sresult`;
            //alert("başarılı");
            //setRedirectStatus(true)
            notify();
            window.location.reload();
            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        }
    }


    /**
     * I want my order to be delivered to my neighbor 
     */

    /**
     * I want my order to be delivered to my neighbor 
     */
    const giveNeighbor = async (event) => {

        if (!neighborForm.apartmentNumber || !neighborForm.flatNumber || !neighborForm.name || !neighborForm.phone || !neighborForm.note) {
            notify("Komşu bilgileri boş");
            return
        }

        let jsondata = {
            type: "GIVE_NEIGHBOR",
            buildingNumber: neighborForm.apartmentNumber,
            flatNumber: neighborForm.flatNumber,
            receiverName: neighborForm.name,
            receiverPhone: neighborForm.phone,
            description: neighborForm.note
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //setVisible(false);

            //modal popup close
            document.getElementById('exampleModal').click();
            //alert("başarılı");
            setRedirectStatus(true);
            notify();
            window.location.reload();

            // clearSelectedOptions();
            // giveNeighborTarget.target.classList.add("active-option");
        }
    }



    /**
    * change delivery time
    */

    const changeDeliveryTime = async (e) => {
        // setOrderToNeighbor(false);
        // setBeDeliveredToMyAddress(false);
        // setOptions3(false);
        // setBellStatus(false);
        // setOptions4(true);
        // setOptions5(false);
        document.getElementById('exampleModal1').click();

    }

    const sendOnChangeDeliveryTime = async (e) => {
        if (!differentDeliveryDate) {
            notify("Lütfen tarih seçiniz");
            return
        }
        let jsondata = {
            type: "I_WILL_RECEIVE_LATER",
            newDeliveryDate: differentDeliveryDate.toLocaleDateString()
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            setRedirectStatus(true);
            notify();
            window.location.reload();

            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        }

    }


    /**
    * delivery to another address
    */

    const deliveryToAnotherAddress = async (e) => {
        // setOrderToNeighbor(false);
        // setBeDeliveredToMyAddress(false);
        // setOptions3(false);
        // setBellStatus(false);
        // setOptions4(false);
        // setOptions5(true);

        document.getElementById('exampleModal2').click();

    }


    const sendOnDeliveryToAnotherAddress = async (e) => {

        if (newAddress.current.value == "") {
            notify("Lütfen adres giriniz");
            return
        }

        if (!selectedSuggestion[0] || !selectedSuggestion[1]) {
            notify("Lütfen adres giriniz");
            return
        }

        let jsondata = {
            type: "CHANGE_DELIVERY_ADDRESS",
            latitude: selectedSuggestion[0],
            longitude: selectedSuggestion[1],
            newAddress: inputAddress
        };
        const url = `tracker/order-tracking/delivery-option/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, jsondata);

        if (glndata.status) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            setRedirectStatus(true);
            notify();
            window.location.reload(); //Refresh if successful

            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        }

    }





    const dropOrderToNeighbor = () => {
        // setOrderToNeighbor(true);
        // setBeDeliveredToMyAddress(false);
        // setOptions3(false);
        // setOptions4(false);
        document.getElementById('exampleModal').click();
    }


    const onChangeValueNeighbor = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _neighborData = { ...neighborForm };
        _neighborData[`${name}`] = val;

        setNeighborFrom(_neighborData);
        console.log(_neighborData)
    }





    /* ==================>>>>>>>>>>>>> Delivery-options-*** <<<<<<<<<<<<<<<<========================== */











    /* ==================>>>>>>>>>>>>> Live-tracking <<<<<<<<<<<<<<<<========================== */


    const getOrderLiveTracking = async () => {
        const url = `order-status-detail/${orderID[1]}`;
        const { data: res } = await GET_BRINGO(url);

        if (res) {
            setLiveTrackingOrder(res.data && res.data[0])
        }

    }

    useEffect(() => {
        let status = liveTrackingOrder && liveTrackingOrder.status;
        if (status == "DELIVERED" || status == "PARTIALLY_DELIVERED" || status == "NOT_DELIVERED" || status == "DELIVERY_POSTPONED") {
            clearInterval(lastPositionIntervalID);
            clearInterval(liveOrderIntervalID);
        }

    }, [liveTrackingOrder])






    const homeIconLocation = newOrder && [newOrder.latitude, newOrder.longitude];


    const homeIcon = L.icon({
        iconUrl: homeLogo,
        iconRetinaUrl: homeLogo,
        iconSize: [48, 48],
    });

    const deliveryIcon = L.icon({
        iconUrl: deliveryLogo,
        iconRetinaUrl: deliveryLogo,
        iconSize: [124, 46],
    });


    useEffect(() => {
        if (map) {
            setInterval(function () {
                map.invalidateSize();
            }, 100);
        }
    }, [map]);

    const position = [42.2974279, -85.628292];
    const position2 = [-8.852507, -45.351563];
    //[37.4219983,-122.084],[41.006584186735424,29.08104130965381]


    const getLastPosition = async (routeID) => {
        const url = `tracker/firebase/last-position-by-route-id?routeId=${routeID}`;
        const { data: res } = await GET(url);

        if (res) {
            setLastPosition({ lastposition: res[0] })
            setLastPositionRq(true);
        }
    }

    // useEffect(() => {
    //    console.log("xxxxx:", lastPositionRq);
    // }, [lastPositionRq])

    // to center two points 
    const bounds = L.latLngBounds(lastPosition.lastposition && [[lastPosition.lastposition.latitude, lastPosition.lastposition.longitude], [newOrder.latitude, newOrder.longitude]]);


    useEffect(() => {

        // if(active== false) return
        if (newOrder && live == true) {
            getLastPosition(newOrder.route_id);
            let intervalID = setInterval(() => {
                getLastPosition(newOrder.route_id);
            }, 10000);

            setIntervalOrder(intervalID);

            //order status check
            let orderCheckID = setInterval(() => {
                getOrderLiveTracking()
            }, 60000);

            setLastPositionIntervalID(intervalID)
            setLiveOrderIntervalID(orderCheckID)

        }

        if (live == false) {
            clearInterval(IntervalOrder)
        }

        live && live === true ?
            document.getElementsByClassName('alotech-chat-head')[0] && document.getElementsByClassName('alotech-chat-head')[0].classList.add('liveHide')
            :
            document.getElementsByClassName('alotech-chat-head')[0] && document.getElementsByClassName('alotech-chat-head')[0].classList.remove('liveHide')


    }, [live])

    // setTimeout(() => {
    //     clearInterval(IntervalOrder)
    // }, 40000);


    /* ==================>>>>>>>>>>>>> Live-tracking-*** <<<<<<<<<<<<<<<<========================== */







    /* ==================>>>>>>>>>>>>> Evaluation <<<<<<<<<<<<<<<<========================== */


    const changeRating = (newRating, name) => {
        setRateState({
            rating: newRating
        });
    }

    const changeRating2 = (newRating, name) => {
        setRateState2({
            rating: newRating
        });
    }


    {/**
    * Update feedback status
    */}
    const handleChange = (event) => {
        setFeedBack(event.target.value);
    }

    {/**
    * Update rateCourier status
    */}
    const handleChangeRateCourier = (event) => {
        setRateCourier(event.target.value);
    }



    const delivererDate = async () => {

        if (!feedBack && !rateCourier && !rateState && !rateState2) {
            notify("Boş alanlar var!");
            return;
        }


        if (rateState && !rateState.rating && rateState2 && !rateState2.rating) {
            notify("Boş alanlar var!");
            return;
        }

        // "Değerlendirme" button is inactive
        setEvaluationPointerEvents(true);
        // if (!rateState && !rateState2) {
        //     notify("Boş alanlar var!");
        //     return;
        // }

        let data = {
            // type: "customer-rate",
            rate: rateState ? rateState.rating : "",
            feedback: feedBack || "",
            rateCourier: rateState2 ? rateState2.rating : "",
            feedbackCourier: rateCourier || ""
        };

        const url = `tracker/order-tracking/customer-rate/${newOrder && newOrder.delivery_code}`;
        const glndata = await POST(url, data);

        if (glndata.status) {
            //alert("başarılı")
            //setRedirectStatus(true)
            //alert("Başarılı");
            // "Değerlendirme" button is active
            setEvaluationPointerEvents(null);
            setFeedBack("");
            setRateCourier("");
            setRateState({
                rating: undefined
            });
            setRateState2({
                rating: undefined
            });

            notify("Değerlendirmeniz kaydedildi.Teşekkür ederiz.");

        }
    }





    /* ==================>>>>>>>>>>>>> Evaluation-*** <<<<<<<<<<<<<<<<========================== */





    const dateFormatForDeliveryHistory = (hr) => {
        //var aa = hr.split("T");
        // return new Date(hr).toLocaleString()
        // let h = hr.split("T")
        // return h;

        var d = new Date(hr);
        d.setHours(d.getHours() + (d.getTimezoneOffset() / -60));
        let hh = d.toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' });
        let dd = d.toLocaleDateString()
        return [dd, hh]
    }







    const accordion1ActivePassiveDeliveryOptions = () => {
        return newOrder && (newOrder.status == "ON_BOARD" || newOrder.status == "ON_THE_WAY_TO_CUSTOMER" || newOrder.status == "CUSTOMER_INFORMED_1") ? true : false
    }


    const accordion2ActivePassiveLiveTracking = () => {
        return newOrder && (newOrder.status == "CUSTOMER_INFORMED_2" || newOrder.status == "CUSTOMER_READINESS_RECEIVED" || newOrder.status == "CUSTOMER_INFORMED_3") ? true : false
    }

    const accordion3ActivePassiveEvalouiton = () => {
        //window.location.reload();
        return newOrder && (newOrder.status == "DELIVERED") ? true : false
    }





    const call = async () => {

        const url = `tracker/order-tracking/courier-call?id=${newOrder && newOrder.delivery_code}&courier-id=${newOrder && newOrder.courier_id}`;
        //const url = `tracker/order-tracking/courier-call?id=9cbc1fdc902a47fa87b55b1ef4609952&courier-id=8a8081ce7cc7cc2d017ce04356da0282`;
        const glndata = await BRINGO_CALL(url);

        if (glndata && glndata.status == 200) {
            //window.location.href = `/sresult`;
            //alert("başarılı")
            //setRedirectStatus(true);
            //notify()
            window.open(`tel:${"02169394909"}`, '_self');

            // clearSelectedOptions();
            // e.target.classList.add("active-option");
        } else {
            notify("Sorun oluştu. Canlı destekten iletişime geçebilirsiniz")
        }


    }

    useEffect(() => {
        //get height of address line and add bottom space
        var addressLineHeight = document.getElementById("addressLine");

        if (addressLineHeight) {
            let rectelm = addressLineHeight.getBoundingClientRect();
            setRect(rectelm.height)
            //console.log("height: " + rect.height);
        }
    }, [newOrder])


    // useEffect(() => {
    //     console.log("RRRRRR: ", rect)
    // }, [rect])

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 3);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);



    const center = {
        lat: 40.731964,
        lng: 34.467527,
    }

    // when adding a new address, map view change
    function ChangeView({ center, zoom }) {
        const map = useMap();
        map.setView(center, zoom);
        return null
    }

    function DraggableMarker() {
        const [draggable, setDraggable] = useState(false)
        const [position, setPosition] = useState(homeIconLocation)
        const markerRef = useRef(null)
        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        setPosition(marker.getLatLng());
                        setSelectedSuggestion([marker.getLatLng().lat, marker.getLatLng().lng]);
                        //console.log(position)
                    }
                },
            }),
            [],
        )
        const toggleDraggable = useCallback(() => {
            setDraggable((d) => !d)
        }, [])

        return (
            <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                position={selectedSuggestion ? selectedSuggestion : position}
                ref={markerRef}>
                {/* <Popup minWidth={90}>
              <span onClick={toggleDraggable}>
                {draggable
                  ? 'Marker is draggable'
                  : 'Click here to make marker draggable'}
              </span>
            </Popup> */}
            </Marker>
        )
    }


    let newAddress = useRef();

    const getSuggestion = async (Val) => {
        const url = `suggestion-external?oid=${newOrder.delivery_code}&keyword=${Val}`;
        const { data: res } = await GET(url);
        if (res) {
            setSuggestion(res)
        }
    }


    //check the address we write
    const onKeyUp = (event) => {
        let eventValue = event.target.value;
        setTimeout(function () {
            if (newAddress.current.value != eventValue || eventValue.length < 3) return
            getSuggestion(eventValue)
        }, 1000);
    }



    const inputAddressOnChange = (event) => {
        setInputAddress(event.target.value);
    }


    // clicking new addresses in the suggestion list
    const handleSelect = (value) => {
        let newAdressValue = value;
        //newAddress.current.value = newAdressValue;
        setSelectedSuggestion([parseFloat(newAdressValue.latitude), parseFloat(newAdressValue.longitude), newAdressValue.address]);
        setInputAddress(newAdressValue.address);
        //console.log("cccc: ", selectedSuggestion);
        //props.setordercood({ "latitude": parseFloat(value.latitude), "longitude": parseFloat(value.longitude) });
        setSuggestion([])
    };

    // enable and disable options
    useEffect(() => {
        switch (deliveryOptionsStatus) {
            case "deliveredToMyAddress":
                setBeDeliveredToMyAddress(true);
                setOrderToNeighbor(false);
                setOptions3(false);
                setOptions4(false);
                setOptions5(false);
                break;
            case "delivertOtherOptions":
                setOrderToNeighbor(false);
                setBeDeliveredToMyAddress(false);
                setOptions3(true);
                setBellStatus(false);
                setOptions4(false);
                setOptions5(false);
                break;
            case "changeDeliveryTime":
                setOrderToNeighbor(false);
                setBeDeliveredToMyAddress(false);
                setOptions3(false);
                setBellStatus(false);
                setOptions4(true);
                setOptions5(false);
                break;
            case "deliveryToAnotherAddress":
                setOrderToNeighbor(false);
                setBeDeliveredToMyAddress(false);
                setOptions3(false);
                setBellStatus(false);
                setOptions4(false);
                setOptions5(true);
                //set default address
                setInputAddress(newOrder && newOrder.address);
                break;
            case "dropOrderToNeighbor":
                setOrderToNeighbor(true);
                setBeDeliveredToMyAddress(false);
                setOptions3(false);
                setOptions4(false);
                break
            default:
                break;
        }
    }, [deliveryOptionsStatus])



    // delivery options and events
    const deliverySave = () => {
        switch (deliveryOptionsStatus) {
            case "deliveredToMyAddress":
                bellStatus == true ? bellClicked() : deliveredToMyAddress()
                break;
            case "delivertOtherOptions":
                delivertOtherOptions()
                break;
            case "changeDeliveryTime":
                sendOnChangeDeliveryTime()
                break;
            case "deliveryToAnotherAddress":
                sendOnDeliveryToAnotherAddress()
                break;
            case "dropOrderToNeighbor":
                giveNeighbor()
                break;

            default:
                bellStatus == true ? bellClicked() : deliveredToMyAddress() //ring the bell
                break;
        }
    }





    return (
        live == false ?
            // redirectStatus == true
            //     ?
            //     <div className="successDiv">
            //         <img src={success_checked} style={{ "width": "90px" }} />
            //         <p>İşleminiz başarıyla gerçekleşti</p>
            //     </div>
            //     :

            newOrder ?

                <div className="allContainer">
                    {/* Loading Comp. */}
                    { evaluationPointerEvents == true && <Spinner showText={false} /> }
                    <ToastContainer
                        position="top-center"
                        progressClassName="toastProgress"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />


                    {/* ================> MODAL <===================== */}
                    {/**
                     * Disable click outside 
                     * data-keyboard="false" data-backdrop="static" 
                    */}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ color: "#dc3545" }}>
                                    Komşu Bilgileri
                                </h5>
                            </div> */}
                                <div className="modal-body" style={{ padding: "0px" }}>
                                    <div className="card text-center">

                                        <div className="card-body">
                                            <div className="fbModal">
                                                <p className="neighborHeaderTitle">Komşu Bilgileri</p>
                                                <div className="neighborDiv">
                                                    <span className="neighborTitle neighborTitleName ">Komşu Adı Soyadı*</span>
                                                    <input type="text" className="neighborInput" onChange={(e) => onChangeValueNeighbor(e, 'name')} />
                                                </div>
                                                <div className="neighborDiv">
                                                    <span className="neighborTitle neighborTitlePhone">Komşu Telefon*</span>
                                                    <input type="text" className="neighborInput" onChange={(e) => onChangeValueNeighbor(e, 'phone')} />
                                                </div>
                                                <div className="fbModalTwoField">
                                                    <div>
                                                        <span className="neighborTitle neighborTitlePhone neighborTwoMleft1">Apartman No*</span>
                                                        <input type="text" className="neighborInputTwoField" onChange={(e) => onChangeValueNeighbor(e, 'apartmentNumber')} />
                                                    </div>
                                                    <div>
                                                        <span className="neighborTitle neighborTitlePhone neighborTwoMleft2">Daire No*</span>
                                                        <input type="text" className="neighborInputTwoField" onChange={(e) => onChangeValueNeighbor(e, 'flatNumber')} />
                                                    </div>
                                                </div>
                                                <div className="neighborDiv">
                                                    <span className="neighborTitle neighborTitleName ">Not*</span>
                                                    <textarea id="evaluationInput1" className="evaluationInput1Font" name="evaluationInput1" onChange={(e) => onChangeValueNeighbor(e, 'note')} />
                                                </div>
                                                <div className="neighborSave">
                                                    <div className="sendEvaluation sendEvaluationFont" onClick={() => dropOrderToNeighbor()} >
                                                        Kaydet
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ================> MODAL <===================== */}






                    {/* ================>DELIVERY TIME MODAL <===================== */}
                    {/**
                     * Disable click outside 
                     * data-keyboard="false" data-backdrop="static" 
                    */}
                    <div className="modal fade" id="exampleModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div className="modal-dialog" role="document" style={{ "height": "430px" }}>
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ color: "#dc3545" }}>
                                    Komşu Bilgileri
                                </h5>
                            </div> */}
                                <div className="modal-body" style={{ padding: "0px" }}>
                                    <div className="card text-center">

                                        <div className="card-body">
                                            <div className="fbModal">
                                                <p className="neighborHeaderTitle" style={{ "margin-bottom": "6px", "margin-top": "6px" }}>Takvim</p>
                                                <Calendar
                                                    onChange={setDifferentDeliveryDate}
                                                    value={differentDeliveryDate}
                                                    //tileDisabled={({ date }) => date.getDay() === 1}
                                                    // maxDate={new Date().setDate(new Date().getDate()-365)}
                                                    maxDate={maxDate}
                                                    minDate={
                                                        minDate
                                                    }
                                                />
                                                <div className="neighborSave">
                                                    <div className="sendEvaluation sendEvaluationFont" onClick={() => changeDeliveryTime()} >
                                                        Kaydet
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ================> DELIVERY TIME MODAL <===================== */}





                    {/* ================>DELIVERY TO ANOTHER ADDRESS  MODAL <===================== */}
                    {/**
                         * Disable click outside 
                         * data-keyboard="false" data-backdrop="static" 
                        */}
                    <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ "z-index": "11111111111" }} >
                        <div className="modal-dialog" role="document" style={{ "height": "85vh" }}>
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ color: "#dc3545" }}>
                                    Komşu Bilgileri
                                </h5>
                            </div> */}
                                <div className="modal-body" style={{ padding: "0px" }}>
                                    <div className="card text-center">

                                        <div className="card-body">
                                            <div className="fbModal">
                                                <p className="neighborHeaderTitle" style={{ "margin-bottom": "6px", "margin-top": "6px" }}>Farklı Adrese Bırak</p>
                                                <div className="neighborDiv">
                                                    <span className="neighborTitle neighborTitlePhone">Adres</span>
                                                    <input type="text" className="neighborInput" style={{ "width": "293px" }} onKeyUp={onKeyUp} ref={newAddress} onChange={(e) => inputAddressOnChange(e)} value={inputAddress} />
                                                    {suggestion ? (
                                                        <div>
                                                            <ul className="suggestionList" style={{ "position": "absolute", "z-index": "1111" }}>
                                                                {suggestion.map((item, i) => {
                                                                    return (
                                                                        <li className="suggestionListItem" style={{ "border": "1px solid #e2e2e2" }} onMouseDown={() => handleSelect(item)} key={i} ><span>{item.address}</span></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                                <div style={{ "height": "calc(100vw - 45px)", "width": "293px", "margin-top": "10px" }}>
                                                    {homeIconLocation && <MapContainer center={selectedSuggestion ? selectedSuggestion : homeIconLocation} zoom={16} scrollWheelZoom={false} whenCreated={setMap}>
                                                        <ChangeView center={selectedSuggestion ? selectedSuggestion : homeIconLocation} zoom={16} />
                                                        <TileLayer
                                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <DraggableMarker />
                                                    </MapContainer>}
                                                </div>
                                                <div className="neighborSave">
                                                    <div className="sendEvaluation sendEvaluationFont" onClick={() => deliveryToAnotherAddress()}>
                                                        Kaydet
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ================> DELIVERY TO ANOTHER ADDRESS MODAL <===================== */}




                    <div className="fb-container-header">
                        <img className="logo" src={logo} />
                        <div className="orderInformation">
                            <div className="orderLineDv orderTop orderLine">
                                <div className="orderLineFieldName"> İsim </div>
                                <div className="orderLineFieldValue"> <span>:</span> {newOrder && newOrder.sign_name} </div>
                            </div>
                            <div className="orderLineDv orderLineWhite">
                                <div className="orderLineWhiteFieldName"> Telefon </div>
                                <div className="orderLineFieldValue"> <span>:</span> {newOrder && newOrder.phone_number} </div>
                            </div>
                            <div className="orderLineDv orderLine">
                                <div className="orderLineFieldName"> Gönderen </div>
                                <div className="orderLineFieldValue"> <span>:</span> {newOrder && newOrder.taken_by || newOrder.sender} </div>
                            </div>
                            <div className="orderLineDv orderLineWhite">
                                <div className="orderLineWhiteFieldName">Sipariş Kodu </div>
                                <div className="orderLineFieldValue"> <span>:</span> {newOrder && newOrder.barcode} </div>
                            </div>
                            <div className="orderLineDv orderLine">
                                <div className="orderLineFieldName">Gönderi Tarihi </div>
                                <div className="orderLineFieldValue"> <span>:</span> {newOrder && new Date(newOrder.order_date).toLocaleDateString()} </div>
                            </div>
                            <div className="orderLineDv orderLineWhite">
                                <div className="orderLineWhiteFieldName">Teslimat Adresi </div>
                                <div id="addressLine" className="orderLineFieldValue"> <span>:</span> {newOrder && newOrder.address}  </div>
                            </div>
                            <div className="orderLineDv orderLine">
                                <div className="orderLineFieldName">Takip No </div>
                                <div className="orderLineFieldValue"> <span>:</span>  {newOrder && newOrder.order_code} </div>
                            </div>
                            <div className="orderLineDv orderLineWhite">
                                <div className="orderLineWhiteFieldName">Gönderi Durumu </div>
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {newOrder && orderStatusSwitch(newOrder.status).label || "-"} </div>  */}
                                <div className="orderLineFieldValue"> <span>:</span>  {newOrder && (orderStatusSwitch(newOrder.status).label != "Depoda Bulunamadı" && orderStatusSwitch(newOrder.status).label != "Teslim Edildi" && orderStatusSwitch(newOrder.status).label != "Teslim Edilemedi") && (newOrder && newOrder.plan && newOrder.plan.start_time != null) ? "Dağıtıma Çıktı" : lastElementInHistory && orderStatusSwitch(lastElementInHistory.status).label || "-" } </div> 
                            </div>
                            <div className="orderLineDv orderBottom orderLine">
                                <div className="orderLineFieldName">Teslimat Zamanı </div>
                                {/* <div className="orderLineFieldValue"> <span>:</span> 02.09.2021 / 14:00-18:00 </div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span> {deliveryDate(order && order.sessionCreatedDate)}, {timeRange(deliveryHour)} </div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {deliveryDate(newOrder && newOrder.delivery_date)} / {newOrder && newOrder.departure_time ? departureTimeAddHours(convertSecondsToHourFormat(newOrder && newOrder.departure_time)) : timeRange(convertSecondsToHourFormat(newOrder && newOrder.planned_start_time))}</div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {(newOrder.status != "ORDER_RECEIVED" && newOrder.status != "SENT_TO_PLAN" && newOrder.status != "REVERT_FROM_PLAN" && newOrder.status != "ORDER_RECEIVED" && newOrder.status != "IN_WAREHOUSE" && newOrder.status != "DELIVERY_PLANNED" && newOrder.status != "IN_TRANSFER_CENTER" && newOrder.status != "ON_THE_WAY_FOR_TRANSFER") && deliveryDate(newOrder && newOrder.delivery_date) + (newOrder.status != "ORDER_ACCEPTED" ? ' / ' + (newOrder && newOrder.departure_time ? departureTimeAddHours(convertSecondsToHourFormat(newOrder && newOrder.departure_time)) : timeRange(convertSecondsToHourFormat(newOrder && newOrder.planned_start_time))): "") || "-"}</div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {(newOrder.status != "ORDER_ACCEPTED" && newOrder.status != "ORDER_RECEIVED" && newOrder.status != "SENT_TO_PLAN" && newOrder.status != "REVERT_FROM_PLAN" && newOrder.status != "ORDER_RECEIVED" && newOrder.status != "IN_WAREHOUSE" && newOrder.status != "DELIVERY_PLANNED" && newOrder.status != "IN_TRANSFER_CENTER" && newOrder.status != "ON_THE_WAY_FOR_TRANSFER") && deliveryDate(newOrder && newOrder.delivery_date) + ' / ' + (newOrder && newOrder.departure_time ? departureTimeAddHours(convertSecondsToHourFormat(newOrder && newOrder.departure_time)) : timeRange(convertSecondsToHourFormat(newOrder && newOrder.planned_start_time))) || "-"}</div> */}

                                {/* newOrder.status != "DELIVERY_PLANNED" */}
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {(newOrder.status != "ORDER_ACCEPTED" && newOrder.status != "ORDER_RECEIVED" && newOrder.status != "SENT_TO_PLAN" && newOrder.status != "REVERT_FROM_PLAN" && newOrder.status != "ORDER_RECEIVED" && newOrder.status != "DELIVERY_PLANNED" && newOrder.status != "IN_WAREHOUSE" && newOrder.status != "IN_TRANSFER_CENTER" && newOrder.status != "ON_THE_WAY_FOR_TRANSFER") && (newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? deliveryDate(newOrder.plan.start_time) : deliveryDate(newOrder.plan.date)) + ' / '  + ((newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? timeRange(hourFormat(newOrder && newOrder.plan.start_time)) : timeRange(hourFormat(newOrder && newOrder.plan.date)))) || "-" }</div> */}
                                
                                {/* <div className="orderLineFieldValue"> <span>:</span>   {(newOrder && hourFormat(newOrder && newOrder.plan.start_time))  || "-" }</div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span> {(newOrder && timeRange(hourFormat(newOrder && newOrder.plan.start_time)))  || "-" }</div>  */}
                            
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {(newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? deliveryDate(newOrder.plan.start_time) : deliveryDate(newOrder.plan.date)) + ' / '  + ((newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? timeRange(hourFormat(newOrder && newOrder.plan.start_time)) : timeRange(hourFormat(newOrder && newOrder.plan.date, "orj")))) || "-" }</div> */}
                                {/* <div className="orderLineFieldValue"> <span>:</span>  {(newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? deliveryDate(newOrder.plan.start_time) : deliveryDate(newOrder.plan.date)) + ' / '  + ((newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? (hourFormat(newOrder && newOrder.plan.start_time)) : (hourFormat(newOrder && newOrder.plan.date, "orj")))) || "-" }</div> */}
                                <div className="orderLineFieldValue"> <span>:</span>  {(newOrder && newOrder.plan && Object.keys(newOrder.plan).length != 0) && (newOrder.plan.start_time ? deliveryDate(newOrder.plan.start_time) : deliveryDate(newOrder.plan.date)) || "-" }</div>
                            </div>
                        </div>
                    </div>

                    <div className="emptyDiv" style={{ "height": rect ? 126 + rect + "px" : "126px" }}></div>

                {/* ================> ORDER TRACKİNG <===================== */}

                    <div className="fb-container-order-tracking">
                        {/* <div class="container"> */}
                            <div class="row orderRow">
                            {/* class="col-12 col-md-10 hh-grayBox pt45 pb20" */}
                                <div class="col-12 col-md-10 hh-grayBox pb20"> 
                                    <div class="row justify-content-between level-1">
                                        <div className={`order-tracking ${(orderTrackingStep.step1 === true) && 'completed'} `}>
                                            <span class="is-complete"></span>
                                            {/* <p>Ordered<br/><span>Mon, June 24</span></p> */}
                                            <p className="orderFont">Paket Kabul Edildi</p>
                                        </div>
                                        <div className={`order-tracking ${(orderTrackingStep.step2 === true) && 'completed'} `}>
                                            <span class="is-complete"></span>
                                            <p className="orderFont">Transfer Merkezinde</p>
                                        </div>
                                        <div className={`order-tracking ${(orderTrackingStep.step3 === true || orderStatusSwitch(newOrder && newOrder.status).label != "Depoda Bulunamadı" && ((newOrder && newOrder.plan && newOrder.plan.start_time != null))) && 'completed'} `}>
                                            <span class="is-complete"></span>
                                            <p className="orderFont">Dağıtıma Çıktı</p>
                                        </div>
                                        {
                                            newOrder && (newOrder.status != 'NOT_DELIVERED' && newOrder.status != 'DELIVERY_POSTPONED' && newOrder.status != 'WILL_BE_RETURN') ?
                                            (
                                            <div className={`order-tracking ${orderTrackingStep.step4 === true && 'completed'} `}>
                                                <span class="is-complete"></span>
                                                <p className="orderFont">Teslim Edildi</p>
                                            </div>  
                                            ):
                                            (
                                            <div className={`order-tracking uncompleted `}>
                                                <span class="un-complete"></span>
                                                <p className="orderFont">Teslim Edilemedi</p>
                                            </div>
                                            )
                                        }
                                    
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>

                {/* ================> ORDER TRACKİNG <===================== */}
                    
                    <div className="fb-container-delivery">
                        <p className="blackFont" style={{ "fontSize": "12px", "marginTop": "12px", "visibility": newOrder && (newOrder.status == "DELIVERED" || newOrder.status == "PARTIALLY_DELIVERED" || newOrder.status == "NOT_DELIVERED" || newOrder.status == "DELIVERY_POSTPONED") ? "hidden" : "visible" }}>Teslimat Zamanı Tahminidir</p>
                    </div>


                    {/* <div>
                <div className="accordion">
                    <div className="accordionHeading">
                        <div className="accordionHeadingContainer" onClick={() => active == "Title1" ? setActive("") : setActive("Title1")}>
                            <p>Title1</p>
                            <i className={(active == "Title1" ? "isRotated" : "") + " fas fa-chevron-right"}></i>
                        </div>
                    </div>
                    <div className={(active == "Title1" ? "show" : "") + " accordionContent"}>
                        <div className="accordionContentContainer">
                            <p>
                                içerik-1   knkjansjkdnjksan asnkjdnkjasndkjnasdjkn jsandjknaskdjnsadjk
                                kmlsakdmklmaslkdmklsa ksmadklmaskldmlsakd kmsalkdmasklmdksamdm
                                kamsdklmsalkdmlksamd kmaslkdmlkasmldmsal mksamldkmkasldmlslamkdmlsak
                                ksdmlkasmdlkmaslkdmlkaskm kmlksmdlkasmlkd
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion">
                    <div className="accordionHeading">
                        <div className="accordionHeadingContainer" onClick={() => active == "Title2" ? setActive("") : setActive("Title2")}>
                            <p>Title2</p>
                            <i className={(active == "Title2" ? "isRotated" : "") + " fas fa-chevron-right"}></i>
                        </div>
                    </div>
                    <div className={(active == "Title2" ? "show" : "") + " accordionContent"}>
                        <div className="accordionContentContainer">
                            <p>
                                içerik-2  knkjansjkdnjksan asnkjdnkjasndkjnasdjkn jsandjknaskdjnsadjk
                                kmlsakdmklmaslkdmklsa ksmadklmaskldmlsakd kmsalkdmasklmdksamdm
                                kamsdklmsalkdmlksamd kmaslkdmlkasmldmsal mksamldkmkasldmlslamkdmlsak
                                ksdmlkasmdlkmaslkdmlkaskm kmlksmdlkasmlkd
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}


                    <div className="fb-container-accordion">

                        <div className="accordion1" style={{ "backgroundColor": accordion1ActivePassiveDeliveryOptions() == true ? "" : "#EAEAEA" }}>
                            <div className="accordionHeading1" onClick={() => accordion1ActivePassiveDeliveryOptions() == true ? active == "Title1" ? setActive("") : setActive("Title1") : ""}>
                                {/* <div className="accordionHeading1" onClick={() => active == "Title1" ? setActive("") : setActive("Title1")}> */}
                                <div className="accordionHeadingContainer1">
                                    <img className="accordionHeadingImg" src={box} style={{ "opacity": accordion1ActivePassiveDeliveryOptions() == true ? "" : "0.5" }} />
                                    <div className="accordionHeadingText">
                                        <p className="accordionHeadingLargeText accordion-font" style={{ "position": "relative", "top": "22px", "color": accordion1ActivePassiveDeliveryOptions() == true ? "" : "#CCB5B4" }}>Teslimat Seçenekleri</p>
                                        <span className="accordionHeadingSmallText accordion-small-font">
                                            <span style={{ "visibility": "hidden" }}>Adresime gelsin</span>
                                            {/* {beDeliveredToMyAddress == true ? "Adresime gelsin" : orderToNeighbor == true ? "Komşuma bırakılmasını istiyorum" : options3 == true  ? "Teslimat zamanınını değiştirmek istiyorum" : ""} */}
                                        </span>
                                    </div>

                                    <i className={(active == "Title1" ? "isRotated" : "") + " fas fa-chevron-right accordionHeadingChevron"} style={{ "color": accordion1ActivePassiveDeliveryOptions() == true ? "" : "#CCB5B4" }}></i>
                                </div>
                            </div>
                            <div className={(active == "Title1" ? "show accordionBorder" : "") + " accordionContent1"}>
                                <div className="accordionContentContainer1 dlOptionsWidth" style={{ "marginTop": "8px" }}>
                                    <div className="dlContainer">
                                        {/* <div className="fbDl" onClick={() => deliveredToMyAddress()}> */}
                                        <div className="fbDl" onClick={() => setDeliveryOptionsStatus("deliveredToMyAddress")}>
                                            {/* <input type="radio" className="dlMl" name="dl" id="address" /> */}
                                            {beDeliveredToMyAddress == true ? <img src={activeRadio} className="dlMl radioH" /> : <img src={passiveRadio} className="dlMl radioH" />}
                                            <p className="dlText">Adresime gelsin</p>
                                        </div>
                                        {/* {beDeliveredToMyAddress == true && <div className="fbDl" style={{ "paddingLeft": "20px" }} onClick={() => bellClicked()} > */}
                                        {beDeliveredToMyAddress == true && <div className="fbDl" style={{ "paddingLeft": "20px" }} onClick={() => { bellStatus == true ? setBellStatus(false) : setBellStatus(true) }} >

                                            {bellStatus == true ? <img src={bellChecked} className="dlMl" /> : <img src={bell} className="dlMl" />}
                                            <p className="dlText">Zili çalma</p>
                                        </div>}
                                        {/* <div className="fbDl" onClick={() => dropOrderToNeighbor()} data-toggle="modal" data-target="#exampleModal" > */}
                                        <div className="fbDl" onClick={() => setDeliveryOptionsStatus("dropOrderToNeighbor")} data-toggle="modal" data-target="#exampleModal" >
                                            {/* <input type="radio" className="dlMl" name="dl" id="neighbor" /> */}
                                            {orderToNeighbor == true ? <img src={activeRadio} className="dlMl radioH" /> : <img src={passiveRadio} className="dlMl radioH" />}
                                            <p className="dlText">Komşuma bırakılmasını istiyorum</p>

                                        </div>
                                        {/* <div className="fbDl" onClick={() => delivertOtherOptions()}> */}
                                        {/* <div className="fbDl" onClick={() => setDeliveryOptionsStatus("delivertOtherOptions")}>    
                                            {options3 == true ? <img src={activeRadio} className="dlMl radioH" /> : <img src={passiveRadio} className="dlMl radioH" />}
                                            <p className="dlText">Siparişi teslim alamayacağım,yarın teslim almak istiyorum</p>
                                        </div> */}
                                        {/* <div className="fbDl" onClick={() => changeDeliveryTime()}> */}
                                        <div className="fbDl" onClick={() => setDeliveryOptionsStatus("changeDeliveryTime")}>
                                            {/* <input type="radio" className="dlMl" name="dl" id="neighbor1" /> */}
                                            {options4 == true ? <img src={activeRadio} className="dlMl radioH" /> : <img src={passiveRadio} className="dlMl radioH" />}
                                            <p className="dlText">Siparişi teslim alamayacağım, teslimat zamanını değiştirmek istiyorum</p>
                                        </div>
                                        {options4 == true && <div className="fbDl" data-toggle="modal" data-target="#exampleModal1">
                                            <div className="dlTimeBox">
                                                <div className="dlTimeImage">
                                                    <img src={deliveryTimeIcon} />
                                                </div>
                                                <div className="dlTimeDateContent">
                                                    <span className="dlTimeTitle">Tarih</span>
                                                    <span className="dlTimeDate">{differentDeliveryDate && differentDeliveryDate.toLocaleDateString() || "Tarih Seç"}</span>
                                                </div>
                                            </div>
                                        </div>}
                                        {/* <div className="fbDl" onClick={() => deliveryToAnotherAddress()} data-toggle="modal" data-target="#exampleModal2"> */}
                                        <div className="fbDl" onClick={() => setDeliveryOptionsStatus("deliveryToAnotherAddress")} data-toggle="modal" data-target="#exampleModal2">
                                            {/* <input type="radio" className="dlMl" name="dl" id="neighbor1" /> */}
                                            {options5 == true ? <img src={activeRadio} className="dlMl radioH" /> : <img src={passiveRadio} className="dlMl radioH" />}
                                            <p className="dlText">Teslimat başka adrese yapılsın</p>
                                        </div>

                                        <div className="fisrtEvaluation">
                                            <div className="sendEvaluation sendEvaluationFont" onClick={() => deliverySave()}>
                                                Kaydet
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <div className="fbDl" style={{ "height": "70px" }} onClick={() => beDeliveredToMyAddress == true && bellClicked()} >
                                            {bellStatus == true ? <img src={bellChecked} className="dlMl" /> : <img src={bell} className="dlMl" />}
                                            <p className="dlText">Zili çalma</p>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="fb-container-accordion fb-container-accordion-mt">

                        {/* <div className="accordion1" style={{ "backgroundColor": accordion2ActivePassiveLiveTracking() == true ? "" : "#EAEAEA" }} > */}
                        <div className="accordion1" style={{ "backgroundColor": (newOrder && newOrder.live_button_enabled == true) ?  "" : "#EAEAEA" }} >
                            {/* <div className="accordionHeading1" onClick={() => accordion2ActivePassiveLiveTracking() == true ? setLive(true) : ""}> */}
                            <div className="accordionHeading1" onClick={() => (newOrder && newOrder.live_button_enabled == true) ? setLive(true) : ""}>
                                <div className="accordionHeadingContainer1 fbAccordionMt">
                                    {/* <img className="accordionHeadingImg" src={deliveryMan} style={{ "opacity": accordion2ActivePassiveLiveTracking() == true ? "" : "0.5" }} /> */}
                                    <img className="accordionHeadingImg" src={deliveryMan} style={{ "opacity": (newOrder && newOrder.live_button_enabled == true) ? "" : "0.5" }} />
                                    <div className="accordionHeadingText">
                                        {/* <p className="accordionHeadingLargeText accordion-font" style={{ "color": accordion2ActivePassiveLiveTracking() == true ? "" : "#CCB5B4" }}>Canlı Takip</p> */}
                                        {/* <p className="accordionHeadingLargeText accordion-font" style={{ "color": accordion2ActivePassiveLiveTracking() == true ? "" : "#CCB5B4" }}>Canlı Takip</p> */}
                                        <p className="accordionHeadingLargeText accordion-font" style={{ "color": (newOrder && newOrder.live_button_enabled == true) ? "" : "#CCB5B4" }}>Canlı Takip</p>
                                    </div>

                                    {/* <i className="fas fa-chevron-right accordionHeadingChevron" style={{ "color": accordion2ActivePassiveLiveTracking() == true ? "" : "#CCB5B4" }}></i> */}
                                    <i className="fas fa-chevron-right accordionHeadingChevron" style={{ "color": (newOrder && newOrder.live_button_enabled == true) ? "" : "#CCB5B4" }}></i>
                                </div>
                            </div>
                            {/* <div className={(active == "Title2" ? "show" : "") + " accordionContent1"}>
                            <div className="accordionContentContainer1">
                                <p>
                                    içerik-3  knkjansjkdnjksan asnkjdnkjasndkjnasdjkn jsandjknaskdjnsadjk
                                    kmlsakdmklmaslkdmklsa ksmadklmaskldmlsakd kmsalkdmasklmdksamdm
                                    kamsdklmsalkdmlksamd kmaslkdmlkasmldmsal mksamldkmkasldmlslamkdmlsak
                                    ksdmlkasmdlkmaslkdmlkaskm kmlksmdlkasmlkd
                                </p>
                            </div>
                        </div> */}
                        </div>

                    </div>


                    <div className="fb-container-accordion fb-container-accordion-mt">

                        <div className="accordion1" style={{ "backgroundColor": accordion3ActivePassiveEvalouiton() == true ? "" : "#EAEAEA" }}>
                            <div className="accordionHeading1" onClick={() => accordion3ActivePassiveEvalouiton() == true ? active == "Title3" ? setActive("") : setActive("Title3") : ""}>
                                <div className="accordionHeadingContainer1 fbAccordionMt">
                                    <img className="accordionHeadingImg" src={rating} style={{ "opacity": accordion3ActivePassiveEvalouiton() == true ? "" : "0.5" }} />
                                    <div className="accordionHeadingText">
                                        <p className="accordionHeadingLargeText accordion-font" style={{ "color": accordion3ActivePassiveEvalouiton() == true ? "" : "#CCB5B4" }}>Değerlendirme</p>
                                    </div>

                                    <i className={(active == "Title3" ? "isRotated" : "") + " fas fa-chevron-right accordionHeadingChevron"} style={{ "color": accordion3ActivePassiveEvalouiton() == true ? "" : "#CCB5B4" }} ></i>
                                </div>
                            </div>
                            <div className={(active == "Title3" ? "showEvaluation accordionBorder" : "") + " accordionContent1"}>
                                <div className="accordionContentContainer1">
                                    <div className="fisrtEvaluation">
                                        <p className="evaluationText evaluationTextMt ">Hizmetimizden memnun kaldınız mı?</p>
                                        <StarRatings
                                            rating={rateState ? rateState.rating : 0}
                                            starRatedColor="#fff007"
                                            changeRating={changeRating}
                                            numberOfStars={5}
                                            name='rating'
                                            starHoverColor='#fff007'
                                            starDimension='25px'
                                        />
                                        <textarea id="evaluationInput1" onChange={handleChange} value={feedBack} maxLength="150" className="evaluationInput1Font" name="evaluationInput1" placeholder="Örneğin; Teslimat sürecindeki bilgilendirmeler yeterliydi" />
                                    </div>
                                    <div className="borderEvaluation"></div>
                                    <div className="fisrtEvaluation">
                                        <p className="evaluationText evaluationTextSecond">Dağıtım sorumlumuzdan memnun kaldınız mı?</p>
                                        <StarRatings
                                            rating={rateState2 ? rateState2.rating : 0}
                                            starRatedColor="#fff007"
                                            changeRating={changeRating2}
                                            numberOfStars={5}
                                            name='rating'
                                            starHoverColor='#fff007'
                                            starDimension='25px'
                                        />
                                        <textarea id="evaluationInput1" onChange={handleChangeRateCourier} value={rateCourier} maxLength="150" className="evaluationInput1Font" name="evaluationInput1" placeholder="Örneğin; Kargo takip hizmeti iyiydi" />
                                    </div>
                                    <div className="fisrtEvaluation">
                                        <div
                                            className="sendEvaluation sendEvaluationFont"
                                            style={{
                                                pointerEvents: evaluationPointerEvents ? "none" : "all", // button active passive events
                                                backgroundColor: evaluationPointerEvents ? "#e4e4e4" : ""
                                            }}
                                            onClick={delivererDate}
                                        >
                                            Gönder
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                    {/* <div className="fb-container-accordion fb-container-accordion-mt">

                    <div className="accordion1">
                        <div className="accordionHeading1" onClick={() => active == "Title4" ? setActive("") : setActive("Title4")}>
                            <div className="accordionHeadingContainer1 fbAccordionMt">
                                <img className="accordionHeadingImg" src={rating} />
                                <div className="accordionHeadingText">
                                    <p className="accordionHeadingLargeText accordion-font">Sipariş Geçmişi</p>
                                </div>

                                <i className={(active == "Title4" ? "isRotated" : "") + " fas fa-chevron-right accordionHeadingChevron"}></i>
                            </div>
                        </div>
                        <div className={(active == "Title4" ? "show accordionBorder" : "") + " accordionContent1"}>
                            <div className="accordionContentContainer1">
                                askldkasmdklsadml

                            </div>
                        </div>
                    </div>

                </div> */}

                    <div className="fb-container-delivery" style={{ "marginTop": "18px" }}>
                        <p className="blackFont" style={{ "fontSize": "12px", "marginTop": "12px" }}>Sipariş Geçmişi</p>
                    </div>


                    <div className="fb-container-accordion fb-container-accordion-mt" style={{ "marginTop": "0px" }}>
                        <table className="table table-bordered deliveryHistoryTable">
                            <tbody>
                                {
                                    newOrder && newOrder.history.map((dt) => {
                                        //console.log("dt: ", newOrder.history);
                                        // in the history table, they will not be listed
                                        // new status : ORDER_ACCEPTED, BRANCH_ACCEPTED, BRANCH_RETURN_ACCEPTED, COURIER_DEBIT
                                        
                                        // Blocked status 
                                        // if (dt.status == "DELIVERED" || dt.status == "PARTIALLY_DELIVERED" || dt.status == "NOT_DELIVERED" || dt.status == "DELIVERY_POSTPONED" || dt.status == "ORDER_RECEIVED"  || dt.status == "BRANCH_ACCEPTED" || dt.status == "BRANCH_RETURN_ACCEPTED" || dt.status == "COURIER_DEBIT") return  // dt.status == "ORDER_ACCEPTED"
                                        if (dt.status == "DELIVERY_PLANNED") return  // dt.status == "ORDER_ACCEPTED"
                                        //let currentOrderStatus = orderStatusSwitch(dt.status).label;
                                        let currentOrderStatus = {"date": dt.date.split("T")[0], "status": orderStatusSwitch(dt.status).label};

                                        //if (window.previousOrderStatus == currentOrderStatus) return
                                        if (window.previousOrderStatus.date == currentOrderStatus.date && window.previousOrderStatus.status == currentOrderStatus.status) return
                                        window.previousOrderStatus = currentOrderStatus;

                                        return (
                                            <tr className="historyTr">
                                                <th className="historySpanDate" scope="row">
                                                    {orderStatusSwitch(dt.status).icon}
                                                </th>
                                                <td className="historySpanTime" colspan="2">
                                                    <span className="historySpanTitle">{orderStatusSwitch(dt.status).label}</span><br />
                                                    <span className="historySpan">Tarih : {dateFormatForDeliveryHistory(dt.date)[0]}</span><br />
                                                    <span className="historySpan">Saat : {dateFormatForDeliveryHistory(dt.date)[1]}</span><br />
                                                    {dt.description != null && <span className="historySpan">Açıklama : {dt.description}</span>}
                                                    {dt.delivery_to != null && <span className="historySpan">Teslim Alan : {dt.delivery_to}</span>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                {/* Last status removed */}
                                {/* {(["DELIVERED", "PARTIALLY_DELIVERED", "NOT_DELIVERED", "DELIVERY_POSTPONED"].includes(newOrder && newOrder.status)) && <tr className="historyTr">
                                    <th className="historySpanDate" scope="row">
                                        {orderStatusSwitch(newOrder.status).icon}
                                    </th>
                                    <td className="historySpanTime" colspan="2">
                                        <span className="historySpanTitle">{orderStatusSwitch(newOrder.status).label}</span><br />
                                        <span className="historySpan">Tarih : {dateFormatForDeliveryHistory(newOrder.delivery_date)[0]}</span><br />
                                        <span className="historySpan">Saat : {dateFormatForDeliveryHistory(newOrder.delivery_date)[1]}</span>
                                    </td>
                                </tr>} */}

                                {/* ON_BOARD -> DAGITIMA ÇIKTI -> Dağıtıma çıktı için sadece plan içindeki start_time kontrol edilecek. */}
                                {/* { newOrder && (orderStatusSwitch(newOrder.status).label != "Depoda Bulunamadı" && orderStatusSwitch(newOrder.status).label != "Teslim Edildi" && orderStatusSwitch(newOrder.status).label != "Teslim Edilemedi") && (newOrder && newOrder.plan && newOrder.plan.start_time != null) && <tr className="historyTr">
                                    <th className="historySpanDate" scope="row">
                                        {orderStatusSwitch("ON_BOARD").icon}
                                    </th>
                                    <td className="historySpanTime" colspan="2">
                                        <span className="historySpanTitle">{orderStatusSwitch("ON_BOARD").label}</span><br />
                                        <span className="historySpan">Tarih : {dateFormatForDeliveryHistory(newOrder.plan.start_time)[0]}</span><br />
                                        <span className="historySpan">Saat : {dateFormatForDeliveryHistory(newOrder.plan.start_time)[1]}</span>
                                    </td>
                                </tr>} */}


                            </tbody>
                        </table>




                    </div>
           
                { (newOrder.images && newOrder.images.length > 0) && <div className="fb-container-images" style={{ "marginTop": "10px" }}>
                    <a data-toggle="modal" data-target="#exampleModal4">Teslimat Resimlerini Göster</a>
                    <DocSlide images={newOrder.images}/>
                </div>}



                    {/* <div className="fb-container-contact-us contactUsMt">
                    <p className="contactUsFont">Bize Ulaşın</p>
                </div> */}


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                </div >
                :
                <div className="loaderSt">
                    <Loader
                        type="Puff"
                        color="#d7443d"
                        height={100}
                        width={100}
                        timeout={20000}
                    />
                </div>
            :
            <div className="allContainer">

                <ToastContainer
                    position="top-center"
                    progressClassName="toastProgress"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div className="liveHeader">
                    <i className="fal fa-arrow-left liveHeaderBack" onClick={() => setLive(false)} ></i>
                    <p className="liveHeaderText">Canlı Takip</p>
                </div>
                <div className="liveMap">
                    <div className="trackingNumber">
                        <p className="trackingNumberText">Takip No : {newOrder && newOrder.order_code}</p>
                    </div>
                    <div className="courier">
                        <img className="courierImg" src={courier} alt="kurye" />
                        <div className="courierName">
                            <p className="courierText">Kurye</p>
                            <p className="courierText">{newOrder && newOrder.courier_name || "isim yok"}</p>
                        </div>
                        <div className="courierPhone" style={{ "opacity": newOrder && newOrder.phone_number ? "" : "0.5", "background": newOrder && newOrder.phone_number ? "#FFC350" : "#e1e1e1" }} onClick={() => newOrder && newOrder.phone_number ? call() : ""}> {/* style={{ "opacity": "0.5" }} */}
                            <a href="#">
                                <img className="courierPhoneImg" src={phone} alt="telefon" />
                            </a>
                        </div>
                    </div>
                    {/* <MapContainer center={homeIconLocation} zoom={8} scrollWheelZoom={false} >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={homeIconLocation} icon={homeIcon}></Marker>
                        <Marker position={["31.13434", "44.3434"]} icon={deliveryIcon}></Marker>
                    </MapContainer> */}

                    {/* <MapContainer center={homeIconLocation} zoom={14} scrollWheelZoom={false} whenCreated={setMap}> */}

                    {/* lastPosition.lastposition && */}
                    {/* lastPositionRq = Request sent to last position service  */}
                    {lastPositionRq == true && <MapContainer bounds={lastPosition.lastposition ? bounds : [homeIconLocation]} zoom={13} scrollWheelZoom={false} whenCreated={setMap}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                        <Marker position={homeIconLocation} icon={homeIcon}></Marker>
                        {(!lastPosition || lastPosition == undefined || lastPosition.length == 0) ? console.log("last-position verisi boş geliyor") : <Marker position={[lastPosition.lastposition.latitude, lastPosition.lastposition.longitude]} icon={deliveryIcon}></Marker>}
                        {/* <Marker position={position}>
                                    <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker> */}
                    </MapContainer>}

                </div>

            </div>

    )
}

export default NewDesing1