/* eslint-disable */
import './App.css';
import LiveApp from './components/liveroute/LiveApp';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage';
import SmsApp from './components/sms/SmsApp';
import Order from './components/order/Order';
import UpdateOrderOptionsApp from './components/updateOrderOptions/UpdateOrderOptionsApp';
import OrderDetailUpdateApp from './components/orderDetailUpdate/OrderDetailUpdateApp';
import DelivererRate from './components/delivererRate/DelivererRate';
import NewBringoInteface from './components/newBringoInterface/BringoInterface'
import EcommerceOrderStatus from './components/eCommerceOrderStatus/EcommerceOrderStatus';
import NewDesing from './components/newDesing/NewDesing1';





function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/live/:order?" render={props => (
          <LiveApp orderQueryParametre={props} />
        )
        } />

        <Route path="/sms/:order?" render={props => (
          <SmsApp orderQueryParametre={props} />
        )
        } />


        <Route path="/order/:order?" render={props => (
          <Order orderQueryParametre={props} />
        )
        } />

        <Route path="/orderDetailUpdate/:order?" render={props => (
          <OrderDetailUpdateApp orderQueryParametre={props} />

        )
        } />
        <Route path="/delivererrate/:order?" render={props => (
          <DelivererRate orderQueryParametre={props} />
        )
        } />

        <Route path="/delivery-options/:order?" render={props => (
          <UpdateOrderOptionsApp orderQueryParametre={props} />
        )
        } />

        {/* <Route path="/bringo-live/:order?" render={props => (
          <NewBringoInteface orderQueryParametre={props} />
        )
        } /> */}

        <Route path="/bringo/:order?" render={props => (
          <EcommerceOrderStatus orderQueryParametre={props} />
        )
        } />

        <Route path="/bringo-live/:order?" render={props => (
          <NewDesing orderQueryParametre={props} />
        )
        } />

        {/* <Route path="/order/" component={Order} /> */}
        {/* <Route component={NotFoundPage} /> */}
      </Switch>
    </BrowserRouter>

  );
}

export default App;

/* eslint-disable */