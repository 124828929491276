import React, { useEffect, useState } from 'react'
import Header from './Header'
import OrderStatus from './OrderStatus'
import './eCommerce.css' /** Css codes of the component */
import { GET_BRINGO } from '../../api'

const EcommerceOrderStatus = (props) => {
    const [orderValue, setOrderValue] = useState({});
    

    const orderID = (props.orderQueryParametre.match.params.order).split('=');
    console.log(orderID);

    const getOrder = async () => {
        const orderID = (props.orderQueryParametre.match.params.order).split('=');
        const url = `order-status/${orderID[1]}`;
        const { data: res } = await GET_BRINGO(url);
        if (res && res.data.length > 0) {
            setOrderValue(res);
        }
    }

    useEffect(() => {
        getOrder();
    }, [])

    
    return (
        <div className="card border-white mb-3" style={{ maxWidth: '100%', fontSize: "18px", overflow: "auto", height: "100%" }}>
            <Header />
            <div className="card-body text-success" style={{ padding: "0", flex: "none", minHeight: "0px" }} >
                <div id="accordion" role="tablist">
                    {/* <DeliveryOptions />
                <LiveTracking />
                <Evaluation /> */}
                    <OrderStatus order={orderValue} />  {/** Order status table component */}
                </div>
            </div>
            {/* <OrderStatus />
        <Footer />
        <ContactUs /> */}
        </div>
    )
}

export default EcommerceOrderStatus