//import React, {Component} from 'react';
/* eslint-disable */
import React, { useEffect } from 'react';
import  './LeftSide.css';
import companyLogo from "../../img/geotechlogo.png";

import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';


const LeftSide = (props) => {

    let root = document.documentElement;
    let apiColor = props.order.apiColor || "#6E6C82";
    let apiIconColor = "#7cade1"

    root.style.setProperty('--api-color', apiColor);
    const visitStatus = (params) => {
        switch (params) {
            case "VISIT_COMPLETED":
                return { outletvisitstatus: `${t('delivered')}`, statuslevel: 4 }
                break;
            case "VISIT_FAILED":
                return { outletvisitstatus: `${t('notDelivered')}`, statuslevel: 4 }
                break;
            default:
                return { outletvisitstatus: `${t('onTheWay')}`, statuslevel: 3 }
        }
    }

    const deliveryDate = (params) => {
        // //const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        // //const month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        // let deliveryDate;
        // deliveryDate = new Date(params);
        // var month = deliveryDate.toLocaleString('default', { month: 'long' });
        // var day = deliveryDate.toLocaleString('default', { weekday: 'long' });;
        // let convertDeliveryTime = deliveryDate.getDate() + " " + month + " " + deliveryDate.getFullYear() + " " + day;
        // return convertDeliveryTime;

        function pad(n) {
            return n < 10 ? '0' + n : n
        };
        

        // const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        // const month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        let deliveryDate = new Date(params);
        // var month = deliveryDate.toLocaleString(localStorage.i18nextLng, { month: 'long' });
        // var day = deliveryDate.toLocaleString(localStorage.i18nextLng, { weekday: 'long' });;
        // let convertDeliveryTime = deliveryDate.getDate() + " " + month + " " + deliveryDate.getFullYear() + " " + day;
        // let convertDeliveryTime = deliveryDate.getDate() + " " + month[deliveryDate.getMonth()] + " " + deliveryDate.getFullYear() + " " + days[deliveryDate.getDay()];
        let dt = pad(deliveryDate.getDate()) + "." + pad(deliveryDate.getMonth() + 1) + "." + deliveryDate.getFullYear();
        return dt;
    }


    const timeRange = (hour) => {
        let spHour = hour.split(":");
        let h = new Date();
        h.setHours(spHour[0], spHour[1], 0);
        h.setHours(h.getHours() + 3);
        return spHour[0] + ":00" + " - " + h.getHours().padLeft() + ":00";
    }

    const convertSecondsToHourFormat = (seconds, showSecond) => {
        // seconds = seconds + (new Date().getTimezoneOffset() * -1 * 60) || 0;
        let mins = Math.floor(seconds / 60);
        let s = Math.round(seconds - (mins * 60));
        let minutes = Math.floor(mins) % 60;
        let hours = Math.floor(mins / 60);
        return hours.padLeft() + ":" + minutes.padLeft() + (showSecond ? ":" + s.padLeft() : "");
    }


    
    var censor = (function() {
        function convertToAsterisk(word) {
            var asteriskSentence = '';
            for(var asterisks=0;asterisks<word.length;asterisks++) {
                asteriskSentence+='*';
            }
            return asteriskSentence;
        }
    
        return function(sentence, bannedWords) {
            sentence    = sentence      || undefined;
            bannedWords = bannedWords   || undefined;
    
            if(sentence!==undefined && bannedWords!==undefined) {
                for(var word=0;word<bannedWords.length;word++) {
                    sentence = sentence.replace(bannedWords[word], convertToAsterisk(bannedWords[word]));
                }
            }
    
            return sentence;
        };
    })();


    const { t, i18n } = useTranslation();
    const { signName, address, orderQuantity, phoneNumber, sessionCreatedDate, outletVisitStatus, actualizationEndTime, startTime, orderCode } = props.order;
    const outletVisit = visitStatus(props.visitStatus);
    let deliveryHour = convertSecondsToHourFormat((outletVisit.statuslevel != 3) ? (actualizationEndTime != null ? actualizationEndTime : startTime)  : startTime);

    return (
        <section className="main-section">
            {/* <header className="header-top">
                <div className="header-content">
                    <div className="header-left branding">
                        <figure className="logo-wrapper">
                            <img className="logo" src={companyLogo} alt="GVG" />
                        </figure>
                    </div>
                    <div className="header-center" id="tabber-wrapper">
                        <h1 className="page-title noselect">{t('orderDetail')}</h1>
                    </div>
                </div>
            </header> */}
             <div className="form-group m-0 fieldName">
                {props.order.companyLogo && <img src={props.order.companyLogo} alt="..." className="img-thumbnail styleLogo"></img>}
            </div>
            <main className="app-main">
                <section className="summary-content m-scroll">
                    <div className="container-fluid">
                        {/* <div className="summary-line">
                            <h5 className="summary-line-title">{t('trackingNumber')} :</h5>
                            <span>{outlet.outletCode}</span>
                        </div> */}
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('trackingNumber')} :</label>
                                    <span className="col">{orderCode}</span>
                                </div>
                            </div>
                        </div>
                        <div className="summary-line">
                            {/* <h5 className="summary-line-title">{t('orderStatus')} :</h5> */}
                            <p><span className="order-status">{outletVisit.outletvisitstatus}</span></p>
                            <div className={`status-leveller level-${outletVisit.statuslevel} mb-3`}>
                                <div className="leveller-circle leveller-1"><i className="fas fa-handshake"></i></div>
                                <div className="leveller-circle leveller-2"><i className="fas fa-truck-loading"></i></div>
                                <div className="leveller-circle leveller-3"><i className="fas fa-truck"></i></div>
                                <div className="leveller-circle leveller-4"><i className="fas fa-box-open"></i></div>
                            </div>
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('deliveryTime')} :</label>
                                    <span className="col">{deliveryDate(sessionCreatedDate)}, {timeRange(deliveryHour)}</span>
                                </div>
                            </div>
                            {/* <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('quantity')} :</label>
                                    <span className="col">{orderQuantity}</span>
                                </div>
                            </div> */}
                        </div>
                        <div className="summary-line">
                            <div className="detail-list">
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('name')} :</label>
                                    <span className="col">{censor(signName, signName.split(" ").filter(d => d).map(e=> e.substring(1)))}</span>
                                </div>
                                {/* <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('phone')} :</label>
                                    <span className="col">{phoneNumber || "-"}</span>
                                </div>
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('email')} :</label>
                                    <span className="col">{outlet.email || "-"}</span>
                                </div> */}
                                <div className="row detail-line">
                                    <label className="col-4 col-sm-3 col-lg-4 col-xl-3 detail-label">{t('adress')} :</label>
                                    <span className="col">{censor(address, address.split(" ").filter(d => d).map(e=> e.substring(1)))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </section>
    )

}

export default LeftSide
/* eslint-disable */